import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import theme from '../theme';

export default [
  {
    id: 'marketing-and-sales',
    name: 'Marketing & Sales',
    content: (
      <React.Fragment>
        <div>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              lg={2}
              xs={6}
            >
              <div
                style={{
                  flex: 1,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignContent: 'flex-start',
                  alignItems: 'flex-start'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant={'h3'}>Readiness Status</Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      backgroundColor: theme.palette.success.main,
                      height: 100,
                      width: 100,
                      borderRadius: 50
                    }}
                  >
                    <Typography variant={'h3'}>95%</Typography>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              lg={2}
              xs={6}
            >
              <Typography variant={'h3'}>
                Main Contact
              </Typography>
              <Typography variant={'h5'}>
                Jac Alvarez
              </Typography>
              <br/>
              <br/>
              <Typography variant={'h3'}>
                Primary Area Contacts
              </Typography>
              <Typography variant={'h5'}>
                Automotive: Michael Machado
              </Typography>
              <Typography variant={'h5'}>
                Insurance: Jac Alvarez
              </Typography>
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
            >
              <Typography variant={'h3'}>
                Required Access/Application
              </Typography>
              <Typography variant={'h5'}>
                Affinity, Requests, Imports, Time clock, Email, One Drive, Zoom
              </Typography>
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
            >
              <Typography variant={'h3'}>
                Primary Remote Access Required
              </Typography>
              <Typography variant={'h5'}>
                Greg Beyer, Alex Drogan,Antonio Dasilva, Mike Mennella, Shawn McNamera, Anthony Medrano,
                Vanessa Romney, Terri Hughes, Karen Urkmez, Bill Hall, Melissa Morra, Ken Hopfensperger,
                Sara Macassi, Insurance:, Josh Craddock, Shane Griffis, Jeff Rosenbaum, Anthony Lobosc,
                ,Charlotte Garu, Dyann Martinez
              </Typography>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    ),
    listItems: [
      {
        title: 'Marketing & Sales Automotive Primary Virtual Office',
        hiddenColumns: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
        headers: [
          {
            name: 'Name'
          }, {
            name: 'Cell Phone #'
          }, {
            name: 'HQ Extension'
          }, {
            name: 'Mac'
          }, {
            name: 'Macbook'
          }, {
            name: 'Windows Laptop'
          }, {
            name: 'Wifi'
          }, {
            name: 'Affinity'
          }, {
            name: 'Requests'
          }, {
            name: 'Imports'
          }, {
            name: 'Time Clock'
          }, {
            name: 'Email'
          }, {
            name: 'OneDrive'
          }, {
            name: 'Zoom'
          }, {
            name: 'Camiel'
          }, {
            name: 'Kronos'
          }],
        rows: [
          [{ value: 'Michael Machado (Manager)' }, {
            value: '215-783-9389',
            type: 'phone'
          }, { value: 330 }, { value: '' }, { value: '' }, { value: null }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: null }, { value: 'x' }],
          [{ value: 'Greg Beyer' }, {
            value: '954-614-9222',
            type: 'phone'
          }, { value: 149 }, { value: 'x' }, { value: '' }, { value: null }, { value: '' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: null }, { value: 'x' }],
          [{ value: 'Alex Drogan' }, {
            value: '561-271-1282',
            type: 'phone'
          }, { value: 147 }, { value: '' }, { value: 'x' }, { value: null }, { value: '' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: null }, { value: 'x' }],
          [{ value: 'Antonio Dasilva' }, {
            value: '561-632-4434',
            type: 'phone'
          }, { value: 323 }, { value: '' }, { value: '' }, { value: null }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: null }, { value: 'x' }],
          [{ value: 'Mike Mennella ' }, {
            value: '954-304-2219',
            type: 'phone'
          }, { value: 206 }, { value: '' }, { value: '' }, { value: null }, { value: '' }, { value: '' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: null }, { value: 'x' }],
          [{ value: 'Shawn McNamera' }, {
            value: '561-504-2664',
            type: 'phone'
          }, { value: 273 }, { value: '' }, { value: '' }, { value: null }, { value: '' }, { value: '' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: null }, { value: 'x' }],
          [{ value: 'Vanessa Romney' }, {
            value: '847-363-1508',
            type: 'phone'
          }, { value: 222 }, { value: '' }, { value: 'x' }, { value: null }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }],
          [{ value: 'Anthony Medrano' }, {
            value: '561-797-0721',
            type: 'phone'
          }, { value: 229 }, { value: '' }, { value: 'x' }, { value: null }, { value: '' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: null }, { value: 'x' }],
          [{ value: 'Terri Hughes' }, {
            value: '904-860-6005',
            type: 'phone'
          }, { value: 332 }, { value: '' }, { value: 'x' }, { value: null }, { value: '' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: '' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: null }, { value: 'x' }],
          [{ value: 'Karen Urkmez' }, {
            value: '561-703-0141',
            type: 'phone'
          }, { value: 331 }, { value: '' }, { value: 'x' }, { value: null }, { value: '' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: '' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: null }, { value: 'x' }],
          [{ value: 'Ken Hopfensberger' }, {
            value: '561-306-9585',
            type: 'phone'
          }, { value: 377 }, { value: '' }, { value: '' }, { value: null }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: null }, { value: '' }],
          [{ value: 'Sara Macassi' }, {
            value: '321-652-5834',
            type: 'phone'
          }, { value: 270 }, { value: '' }, { value: 'x' }, { value: null }, { value: '' }, { value: 'x' }, { value: 'x' }, { value: '' }, { value: 'x' }, { value: '' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: null }, { value: 'x' }],
          [{ value: 'Bill Hall' }, {
            value: '703-595-5199',
            type: 'phone'
          }, { value: 486 }, { value: 'x' }, { value: '' }, { value: null }, { value: '' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: '' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }],
          [{ value: 'Melissa Morra' }, {
            value: '561-504-8316',
            type: 'phone'
          }, { value: 248 }, { value: '' }, { value: '' }, { value: null }, { value: '' }, { value: '' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: null }, { value: 'x' }]
        ]
      },
      {
        title: 'Marketing & Sales Insurance Primary Virtual Office',
        hiddenColumns: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
        headers: [
          {
            name: 'Name'
          }, {
            name: 'Cell Phone #'
          }, {
            name: 'HQ Extension'
          }, {
            name: 'Mac'
          }, {
            name: 'Macbook'
          }, {
            name: 'Windows Laptop'
          }, {
            name: 'Wifi'
          }, {
            name: 'Affinity'
          }, {
            name: 'Requests'
          }, {
            name: 'Imports'
          }, {
            name: 'Time Clock'
          }, {
            name: 'Email'
          }, {
            name: 'OneDrive'
          }, {
            name: 'Zoom'
          }, {
            name: 'Camiel'
          }, {
            name: 'Kronos'
          }],
        rows: [
          [{ value: 'Josh Craddock' }, {
            value: '561-305-5674',
            type: 'phone'
          }, { value: 259 }, { value: '' }, { value: '' }, { value: null }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: '' }, { value: null }, { value: 'x' }],
          [{ value: 'Shane Griffis' }, {
            value: '954-547-3722',
            type: 'phone'
          }, { value: 203 }, { value: 'x' }, { value: '' }, { value: null }, { value: '' }, { value: 'x' }, { value: '' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: '' }, { value: null }, { value: 'x' }],
          [{ value: 'Jeff Rosenbaum' }, {
            value: '954-609-9282',
            type: 'phone'
          }, { value: 205 }, { value: '' }, { value: '' }, { value: null }, { value: '' }, { value: '' }, { value: '' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: '' }, { value: null }, { value: 'x' }],
          [{ value: 'Anthony Lobosco' }, {
            value: '917-855-0021',
            type: 'phone'
          }, { value: 287 }, { value: '' }, { value: '' }, { value: 'x' }, { value: '' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: '' }, { value: null }, { value: 'x' }],
          [{ value: 'Charlottte Garu' }, {
            value: '954-706-9959',
            type: 'phone'
          }, { value: 308 }, { value: '' }, { value: '' }, { value: null }, { value: '' }, { value: 'x' }, { value: '' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: '' }, { value: null }, { value: 'x' }],
          [{ value: 'Dyann Martinez' }, {
            value: '407-595-6533',
            type: 'phone'
          }, { value: 298 }, { value: '' }, { value: '' }, { value: null }, { value: '' }, { value: 'x' }, { value: '' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: '' }, { value: null }, { value: 'x' }]
        ]
      }
    ]
  },
  {
    id: 'technology',
    name: 'Technology',
    content: (
      <React.Fragment>
        <div>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              lg={2}
              xs={6}
            >
              <div
                style={{
                  flex: 1,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignContent: 'flex-start',
                  alignItems: 'flex-start'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant={'h3'}>Readiness Status</Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      backgroundColor: theme.palette.warning.main,
                      height: 100,
                      width: 100,
                      borderRadius: 50
                    }}
                  >
                    <Typography variant={'h3'}>80%</Typography>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              lg={2}
              xs={6}
            >
              <Typography variant={'h3'}>
                Main Contact
              </Typography>
              <Typography variant={'h5'}>
                Trevor Moore
              </Typography>
              <br/>
              <br/>
              <Typography variant={'h3'}>
                Primary Area Contacts
              </Typography>
              <Typography variant={'h5'}>
                Engineering: Sean O’Niel
              </Typography>
              <Typography variant={'h5'}>
                IT: Rodrigo Brasil
              </Typography>
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
            >
              <Typography variant={'h3'}>
                Required Access/Application
              </Typography>
              <Typography variant={'h5'}>
                VPN: Remote Access to admin all servers, VMS, & desktops
              </Typography>
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
            >
              <Typography variant={'h3'}>
                Primary Remote Access Required
              </Typography>
              <Typography variant={'h5'}>
                Anthony Altieri, Dean Morace, Rob Mitchell, Esdras Alexandre, Jayson Lima, Steven Delizin
              </Typography>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    ),
    listItems: [
      {
        title: 'Engineering Primary Virtual Office',
        headers: [
          {
            'name': 'Name'
          }, {
            'name': 'Phone'
          }, {
            'name': 'VPN Connection'
          }, {
            'name': 'O and S Drive Access'
          }, {
            'name': 'hMail Server'
          }, {
            'name': 'DB Server Access'
          }, {
            'name': 'Web Server Access'
          }, {
            'name': 'Report Man Server Access'
          }, {
            'name': 'All Internal Application Access'
          }, {
            'name': 'Office 365'
          }, {
            'name': 'Outlook'
          }, {
            'name': 'MSSQL Server Management Studio'
          }, {
            'name': 'Microsoft SQL Server Report Builder (SSRS)'
          }, {
            'name': 'TextPad'
          }, {
            'name': 'NotePad ++'
          }, {
            'name': 'VS Code '
          }, {
            'name': 'Visual studio 2017 community version'
          }, {
            'name': 'MSSQL Express'
          }, {
            'name': 'Crystal Report Designer'
          }, {
            'name': 'VS Basic 6'
          }, {
            'name': 'MS Access'
          }, {
            'name': 'Tableau'
          }, {
            'name': 'Filezilla'
          }, {
            'name': 'Javascript add-on to Manual  Imports'
          }],
        rows: [
          [{ value: 'Anthony Altieri' }, { value: '954-536-4929' }, { value: 'x' }, { value: 'x' }, { value: null }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: null }, { value: null }, { value: null }, { value: null }, { value: null }, { value: null }, { value: null }, { value: null }, { value: null }, { value: null }, { value: 'N/A' }],
          [{ value: 'Dean Morace' }, { value: '561-654-8412' }, { value: 'x' }, { value: 'x' }, { value: null }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: 'x' }, { value: null }, { value: null }, { value: null }, { value: null }, { value: null }, { value: null }, { value: null }, { value: null }, { value: null }, { value: null }, { value: 'N/A' }]
        ]
      },
      {
        title: 'IT Primary Virtual Office',
        headers: [
          {
            'name': 'Name'
          }, {
            'name': 'Phone'
          }, {
            'name': 'VPN Connection'
          }, {
            'name': 'O and S Drive Access'
          }, {
            'name': 'DB Server Access'
          }, {
            'name': 'Web Server Access'
          }, {
            'name': 'Report Man Server Access'
          }, {
            'name': 'All Internal Application Access'
          }, {
            'name': 'Office 365'
          }, { 'name': 'Outlook' }, {
            'name': 'MSSQL Server Management Studio'
          }, {
            'name': '1Password'
          }, {
            'name': 'Microsoft Teams'
          }, {
            'name': 'Windows Administrative Tools'
          }, {
            'name': 'FileZilla'
          }, {
            'name': 'Galaxy CC Badge app'
          }],
        rows: [
          [{ 'value': 'Robert Mitchell' }, { 'value': '561-860-3622' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': '' }, { 'value': '' }, { 'value': '' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }],
          [{ 'value': 'Richard Marnell' }, { 'value': '561-251-8747' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': '' }, { 'value': '' }, { 'value': '' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': '' }, { 'value': '' }, { 'value': '' }],
          [{ 'value': 'Steven Delizin' }, { 'value': '561-229-2135' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': '' }, { 'value': '' }, { 'value': '' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': '' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': '' }, { 'value': '' }, { 'value': '' }],
          [{ 'value': 'Esdras Alexandre' }, { 'value': '561-436-2988' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': '' }, { 'value': '' }, { 'value': '' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': '' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': '' }, { 'value': '' }, { 'value': '' }],
          [{ 'value': 'Jayson Lima' }, { 'value': '954-254-0191' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': '' }, { 'value': '' }, { 'value': '' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': '' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': '' }, { 'value': '' }, { 'value': '' }],
          [{ 'value': 'Raymond Bones' }, { 'value': '561-452-8431' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': '' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': '' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': '' }, { 'value': '' }],
          [{ 'value': 'Rodrigo Brasil' }, { 'value': '561-955-0468' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': 'x' }, { 'value': '' }]
        ]
      },
      {
        title: 'Productivity Software',
        headers: [],
        rows: []
      },
      {
        title: 'Links',
        headers: [{
          name: 'Link'
        }, {
          name: 'Description'
        }],
        rows: [
          [{
            value: 'http://gofile.me/4YHUu/syiwor9P2',
            type: 'ext_link'
          }, { value: 'Link for accessing O and S drivers without company network connection / VPN' }]
        ]
      }
    ]
  },
  {
    id: 'network',
    name: 'Network',
    content: (
      <React.Fragment>
        <div>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              lg={2}
              xs={6}
            >
              <div
                style={{
                  flex: 1,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignContent: 'flex-start',
                  alignItems: 'flex-start'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant={'h3'}>Readiness Status</Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      backgroundColor: theme.palette.success.main,
                      height: 100,
                      width: 100,
                      borderRadius: 50
                    }}
                  >
                    <Typography variant={'h3'}>100%</Typography>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              lg={2}
              xs={6}
            >
              <Typography variant={'h3'}>
                Main Contact
              </Typography>
              <Typography variant={'h5'}>
                Jeff Wiener
              </Typography>
              <br/>
              <br/>
              <Typography variant={'h3'}>
                Primary Area Contacts
              </Typography>
              <Typography variant={'h5'}>
                Jon Borowka
              </Typography>
              <Typography variant={'h5'}>
                Noel Suarez
              </Typography>
              <Typography variant={'h5'}>
                Gerry Brummer
              </Typography>
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
            >
              <Typography variant={'h3'}>
                Required Access/Application
              </Typography>
              <Typography variant={'h5'}>
                Main Cheyenne, TowNet, Live TowNet, InContact
              </Typography>
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
            >
              <Typography variant={'h3'}>
                Primary Remote Access Required
              </Typography>
              <Typography variant={'h5'}>
                Melanie Alvez, Michelle Hudson, Erin Alfano, Myknel Jean-Louis, Patrick Nahoum, Josh Fischer,
                Daniela Menjivar-Diaz, Alex Gandon, Shaun Medeiros, Ashley Fenn
              </Typography>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    ),
    listItems: [
      {
        title: 'Network Primary Virtual Office',
        headers: [
          {
            name: 'Name'
          }, {
            name: 'Cheyenne'
          }, {
            name: 'Email'
          }, {
            name: 'Townet'
          }, {
            name: 'Live Townet'
          }, {
            name: 'InContact'
          }, {
            name: 'Department Links on Side of Cheyenne'
          }
        ],
        rows: [
          [{ value: 'Linda Mareus' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Leonard Nicotra' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Wilguy Oreste' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Brandee Wilson' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Oriana Jackson' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Aaron Santiago' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Shayla Newbold' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Christina Potts' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Christopher Leveque' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Davielle Blakeney' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Brandon Hahn' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Schline Joseph' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Annasishaa Rutledge' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Katia Altidor ' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Janay Hunter' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Vanessa Vergin' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Stephanie Francois' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Shantel Fullwood' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Robin Jackson' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Bridget Delarosa' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Shakira Robinson' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Jocelove Verdieu' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Adjanie Etienne' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Tony Almendarez' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Deidre Bates ' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Jeffrey LaFrance' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Joniesha Johnson' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Demetria Poitier' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Samantha Demesier' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Crystal Richard' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Bradley Dolce ' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Merline Dany ' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Brutchine Faustin' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Meredith Mckever' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Rochelle Smith' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Christina Porter' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Ketia Fequiere' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Shellyann Reid' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Adline Sauveur' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Elizabeth Ramos' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Eddie Bonilla ' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Dacia Brown ' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Dolores Telson' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Jeniffer Daout' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Davina Harmon' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Trakiya Patterson' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Patrick Narin' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Brandy Dufresne' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Ashley Latarullo' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Tom Noles' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Maketa Fulton' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Donna Knowles' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Wiler Lopes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Eileen Simonson' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Amber Matukas' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Catherine Curry' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Chase Calver' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Nathalie Filossaint' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Tatiana Sandifer' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Shayla Baker' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Melanie Alvez' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Mycnel Jean-Louis' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Erin Alfano' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Michelle Hudson' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Yallisonette Vega' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Ashley Fenn' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Sam Mahana' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Tyler Mauermann' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }],
          [{ value: 'Brian Amarnick' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'yes' }, { value: 'no' }]
        ]
      }
    ]
  },
  {
    id: 'insurance',
    name: 'Insurance',
    content: (
      <React.Fragment>
        <div>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              lg={2}
              xs={6}
            >
              <div
                style={{
                  flex: 1,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignContent: 'flex-start',
                  alignItems: 'flex-start'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant={'h3'}>Readiness Status</Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      backgroundColor: theme.palette.warning.main,
                      height: 100,
                      width: 100,
                      borderRadius: 50
                    }}
                  >
                    <Typography variant={'h3'}>85%</Typography>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              lg={2}
              xs={6}
            >
              <Typography variant={'h3'}>
                Main Contact
              </Typography>
              <Typography variant={'h5'}>
                Howard Goldfarb
              </Typography>
              <br/>
              <br/>
              <Typography variant={'h3'}>
                Primary Area Contacts
              </Typography>
              <Typography variant={'h5'}>
                Claims: Eric Sharfstein
              </Typography>
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
            >
              <Typography variant={'h3'}>
                Required Access/Application
              </Typography>
              <Typography variant={'h5'}>
                InContact, Cheyenne Access, (department link), Cheyenne email, Gap email Box Access, Affinity,
                Camiel, VSC Estimator (on the website), Interest Calculator/Manual calculation (on the website),
                Cancellation Wheel (add link to the website)
              </Typography>
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
            >
              <Typography variant={'h3'}>
                Primary Remote Access Required
              </Typography>
              <Typography variant={'h5'}>
                Lorenzo Hampton, Allison Notaras, Elisa Rutigliano, Alexis Chapelle, Sherry-Ann Fergusen, Carol
                Durbin, Rene Rodriguez, Linda Olmo
              </Typography>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    ),
    listItems: [
      {
        title: 'Insurance Org',
        headers: [{
          name: 'Name'
        }, {
          name: 'Mobile Phone'
        }, {
          name: 'Work Ext.'
        }],
        rows: [
          [{ value: 'Allison Notaras' }, { value: '954-553-3958', type: 'phone' }, { value: 252 }],
          [{ value: 'Jennifer Cibotti' }, { value: '609-742-1614', type: 'phone' }, { value: 199 }],
          [{ value: 'Juan Yambo' }, { value: '561-856-5450', type: 'phone' }, { value: 281 }],
          [{ value: 'Jennifer Mcrea' }, { value: '954-803-3272', type: 'phone' }, { value: 463 }],
          [{ value: 'Elisa Rutigliano' }, { value: '561-945-2360', type: 'phone' }, { value: 429 }],
          [{ value: 'Shakeria Young ' }, { value: '954-892-3021', type: 'phone' }, { value: 459 }],
          [{ value: 'Yolanda (Jolie) Vidro' }, { value: '561-531-9488', type: 'phone' }, { value: 365 }],
          [{ value: 'Alexis Chappel' }, { value: '561-906-5179', type: 'phone' }, { value: 462 }],
          [{ value: ' Andrea Taylor' }, { value: '561-302-3185', type: 'phone' }, { value: 234 }],
          [{ value: 'Arlene Hodge' }, { value: '954-200-2426', type: 'phone' }, { value: 367 }],
          [{ value: 'Maria Eisler' }, { value: '954-907-8170', type: 'phone' }, { value: 223 }],
          [{ value: 'Sherry-anne Ferguson ' }, { value: '754-303-4579', type: 'phone' }, { value: 146 }],
          [{ value: 'Wilnese Metelus' }, { value: '561-414-4608', type: 'phone' }, { value: 363 }],
          [{ value: 'Carol Durbin ' }, { value: '330-671-8694', type: 'phone' }, { value: 299 }],
          [{ value: 'Jackie Dukes' }, { value: '561-509-4183', type: 'phone' }, { value: 381 }],
          [{ value: 'Jeffrey Pichardo' }, { value: '561-225-4653', type: 'phone' }, { value: 216 }],
          [{ value: 'Debra Bishop' }, { value: '954-934-8523', type: 'phone' }, { value: 142 }],
          [{ value: 'Rene Rodriguez' }, { value: '954-934-8523', type: 'phone' }, { value: 326 }],
          [{ value: 'Alise Abraham' }, { value: '305-439-0721', type: 'phone' }, { value: 440 }],
          [{ value: 'Alex Montoya' }, { value: '561-315-2985', type: 'phone' }, { value: 240 }],
          [{ value: 'Vincent Prochilo' }, { value: '954-675-6687', type: 'phone' }, { value: 382 }],
          [{ value: 'Linda Olmo' }, { value: '561-305-9668', type: 'phone' }, { value: 317 }]
        ]
      }
    ]
  },
  {
    id: 'finance',
    name: 'Finance',
    content: (
      <React.Fragment>
        <div>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              lg={2}
              xs={6}
            >
              <div
                style={{
                  flex: 1,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignContent: 'flex-start',
                  alignItems: 'flex-start'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant={'h3'}>Readiness Status</Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      backgroundColor: theme.palette.error.main,
                      height: 100,
                      width: 100,
                      borderRadius: 50
                    }}
                  >
                    <Typography variant={'h3'}>TBD</Typography>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              lg={2}
              xs={6}
            >
              <Typography variant={'h3'}>
                Main Contact
              </Typography>
              <Typography variant={'h5'}>
                Lisa Clark
              </Typography>
              <br/>
              <br/>
              <Typography variant={'h3'}>
                Primary Area Contacts
              </Typography>
              <Typography variant={'h5'}>
                Stephine
              </Typography>
              <Typography variant={'h5'}>
                Julie
              </Typography>
              <Typography variant={'h5'}>
                Rose
              </Typography>
              <Typography variant={'h5'}>
                Peter
              </Typography>
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
            >
              <Typography variant={'h3'}>
                Required Access/Application
              </Typography>
              <Typography variant={'h5'}>
                Main Cheyenne, QuickBooks (need to virtualize), Affinity, S & V Drive (VPN), Incontact
              </Typography>
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
            >
              <Typography variant={'h3'}>
                Primary Remote Access Required
              </Typography>
              <Typography variant={'h5'}>
                GMonica, Violeta, Patti Weatherford, Laura Bruce, Andrea Robinson
              </Typography>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    ),
    listItems: [
      {
        title: 'Finance Primary Virtual Office',
        headers: [
          {
            'name': 'Name'
          }, {
            'name': 'Cell #'
          }, {
            'name': 'HQ Extension'
          }, {
            'name': 'Mac'
          }, {
            'name': 'Macbook'
          }, {
            'name': 'Windows Desktop'
          }, {
            'name': 'Windows Laptop'
          }, {
            'name': 'Wifi'
          }, { 'name': 'Internet-Ethernet' }, { 'name': 'Affinity' }, { 'name': 'Requests' }, { 'name': 'Imports' }, { 'name': 'Email' }, { 'name': 'OneDrive' }, { 'name': 'Zoom' }, { 'name': 'Camiel' }, { 'name': 'Kronos' }, { 'name': 'Cheynne Department Links' }, { 'name': 'Drives' }, { 'name': 'Cheyenne*' }, { 'name': 'Quickbooks' }, { 'name': null }, { 'name': 'Office Email' }, { 'name': 'Personal Email Add' }, { 'name': 'Laptop' }, { 'name': 'Dual Monitor' }, { 'name': 'Mouse' }, { 'name': 'Printer' }],
        rows: [
          [{ value: 'Lisa Clark' }, { value: '954-254-1172' }, { value: 209 }, { value: null }, { value: '' }, { value: 'X' }, { value: '' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: '' }, { value: '' }, { value: null }, { value: 'X' }, { value: 'X' }, { value: '' }, { value: '' }, {
            value: ''
          },
            { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }],
          [{ value: 'Julie Murray' }, { value: '954-253-2607' }, { value: 254 }, { value: null }, { value: '' }, { value: 'X' }, { value: '' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: '' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: null }, { value: 'X' }, { value: '' }, { value: 'S & V Drive' }, { value: 'X' }, {
            value: 'X'
          },
            { value: '' }, { value: 'jmurray@nationsafedrivers.com' }, { value: 'j2murray@icloud.com' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: 'X' }],
          [{ value: 'Rose Hall' }, { value: '561-400-9961' }, { value: 347 }, { value: null }, { value: '' }, { value: '' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: '' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: null }, { value: 'X' }, { value: '' }, { value: 'S & V Drive' }, { value: 'X' }, {
            value: 'X'
          },
            { value: '' }, { value: 'rhall@nationsafedrivers.com' }, { value: 'vinnyswife06@aol.com' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: 'X' }],
          [{ value: 'Stephanie Paul' }, { value: '330-807-8505' }, { value: 396 }, { value: null }, { value: 'X' }, { value: '' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: '' }, { value: '' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: null }, { value: 'X' }, { value: '' }, { value: 'S & V Drive' }, { value: 'X' }, {
            value: 'X'
          },
            { value: '' }, { value: 'spaul@nationsafedrivers.com' }, { value: 'stephpaul078@gmail.com' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: 'X' }],
          [{ value: 'Monica Gomez' }, { value: '561-563-2255' }, { value: 243 }, { value: null }, { value: '' }, { value: '' }, { value: '' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: '' }, { value: '' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: null }, { value: 'X' }, { value: '' }, { value: 'S & V Drive' }, { value: 'X' }, {
            value: 'X'
          },
            { value: '' }, { value: 'mgomez@nationsafedrivers.com' }, { value: '' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: 'X' }],
          [{ value: 'Violeta Spasovska' }, { value: '786-271-9004' }, { value: 362 }, { value: null }, { value: '' }, { value: '' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: '' }, { value: '' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: null }, { value: 'X' }, { value: '' }, { value: 'S & V Drive' }, { value: 'X' }, {
            value: 'X'
          }, { value: '' }, { value: 'vspasovska@nationsafedrivers.com' }, { value: 'vspa999@gmail.com' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: 'X' }]
        ]
      },
      {
        title: 'Processing Primary Virtual Office',
        headers: [
          {
            'name': 'Name'
          }, {
            'name': 'Cell #'
          }, {
            'name': 'HQ Extension'
          }, {
            'name': 'Mac'
          }, {
            'name': 'Macbook'
          }, {
            'name': 'Windows Desktop'
          }, {
            'name': 'Windows Laptop'
          }, {
            'name': 'Wifi'
          }, { 'name': 'Internet-Ethernet' }, { 'name': 'Affinity' }, { 'name': 'Requests' }, { 'name': 'Imports' }, { 'name': 'Email' }, { 'name': 'OneDrive' }, { 'name': 'Zoom' }, { 'name': 'Camiel' }, { 'name': 'Kronos' }, { 'name': 'Cheynne Department Links' }],
        rows: [
          [{ value: 'Laura Bruce' }, { value: '954-263-3444' }, { value: 357 }, { value: null }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: '' }, { value: '' }, { value: null }, { value: 'X' }, { value: 'X' }],
          [{ value: 'Peter Bloxam' }, { value: '561-352-7942' }, { value: 202 }, { value: null }, { value: '' }, { value: '' }, { value: 'X' }, { value: 'X' }, { value: '' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: 'X' }, { value: '' }, { value: '' }, { value: null }, { value: 'X' }, { value: 'X' }],
          [{ value: 'Patti Weatherford ' }, { value: '954-803-3370' }, { value: 244 }, { value: null }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: 'X' }, { value: 'X' }, { value: '' }, { value: 'X' }, { value: '' }, { value: '' }, { value: null }, { value: 'X' }, { value: 'X' }],
          [{ value: 'Andrea Robinson' }, { value: '954-536-9725' }, { value: 258 }, { value: null }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: 'X' }, { value: 'X' }, { value: '' }, { value: 'X' }, { value: '' }, { value: '' }, { value: null }, { value: '' }, { value: '' }]
        ]
      }
    ]
  },
  {
    id: 'hr',
    name: 'HR',
    content: (
      <React.Fragment>
        <div>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              lg={2}
              xs={6}
            >
              <div
                style={{
                  flex: 1,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignContent: 'flex-start',
                  alignItems: 'flex-start'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant={'h3'}>Readiness Status</Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      backgroundColor: theme.palette.success.main,
                      height: 100,
                      width: 100,
                      borderRadius: 50
                    }}
                  >
                    <Typography variant={'h3'}>100%</Typography>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              lg={2}
              xs={6}
            >
              <Typography variant={'h3'}>
                Main Contact
              </Typography>
              <Typography variant={'h5'}>
                Wanda Lopez
              </Typography>
              <br/>
              <br/>
              <Typography variant={'h3'}>
                Primary Area Contacts
              </Typography>
              <Typography variant={'h5'}>
                Esther Ramirez
              </Typography>
              <Typography variant={'h5'}>
                Nicole Clark
              </Typography>
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
            >
              <Typography variant={'h3'}>
                Required Access/Application
              </Typography>
              <Typography variant={'h5'}>
                Kronos, eMail
              </Typography>
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
            >
              <Typography variant={'h3'}>
                Primary Remote Access Required
              </Typography>
              <Typography variant={'h5'}>
                Lylli Rojas, Lori Karol
              </Typography>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    ),
    listItems: [
      {
        title: 'Finance Primary Virtual Office',
        headers: [{ 'name': 'Kronos' }, { 'name': 'eMail' }],
        rows: [
          [{ 'value': 'Esther Ramirez ' }, { 'value': 'x' }, { 'value': 'x' }],
          [{ 'value': 'Nicole Clark' }, { 'value': 'x' }, { 'value': 'x' }],
          [{ 'value': 'Lylli Rojas' }, { 'value': 'x' }, { 'value': 'x' }],
          [{ 'value': 'Lori Karol' }, { 'value': 'x' }, { 'value': 'x' }]
        ]
      }
    ]
  }
];
