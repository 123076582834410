import React, { cloneElement, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  AppBar,
  Button,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Parallax, Background } from 'react-parallax';
import { BcpTable, TopPageBox } from '../../components';
import {
  People as HRIcon,
  Assignment as ClaimsIcon,
  Language as NetworkingIcon,
  PermDataSetting as ITIcon,
  Pages,
  Notifications,
  LibraryBooks,
  AccountBalance as FinanceIcon,
  AttachMoney as SalesIcon, KeyboardArrowRight
} from '@material-ui/icons';
import { Link, useParams } from 'react-router-dom';
import find from 'lodash/find';

import orgs from '../../content/organizations';

var tinycolor = require('tinycolor2');
const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    height: '100%',
    width: '100%'
  },
  bottomDiv: {
    height: 300
  },
  selectedItem: {
    backgroundColor: tinycolor(theme.palette.primary.light).setAlpha(.5).toRgbString()
  },
  topListItem: {
    borderTop: '1px solid #eeeeee'
  },
  icon: {
    fontSize: 50
  },
  topImage: {
    marginTop: -100
  },
  standardRow: {
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(15)}px ${theme.spacing(5)}px ${theme.spacing(15)}px`
    },
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`
    }
  },
  altRow: {
    minHeight: 100,
    marginTop: 100,
    backgroundColor: theme.palette.primary.light,
    padding: `${theme.spacing(5)}px ${theme.spacing(15)}px ${theme.spacing(5)}px ${theme.spacing(15)}px`
  },
  altRowText: {
    color: theme.palette.primary.contrastText
  },
  darkBlue: {
    backgroundColor: '#22313f'
  },
  standardDiv: {
    padding: theme.spacing(4),
    marginTop: 50
  },
  textCenter: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  topBg: {
    height: 600
  },
  parallaxHeight: {
    [theme.breakpoints.up('xs')]: {
      height: 200
    },
    [theme.breakpoints.up('md')]: {
      height: 400
    },
    [theme.breakpoints.up('lg')]: {
      height: 600
    }
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const Home = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  let { org } = useParams();
  const [orgData, setOrgData] = useState({});
  const [image, setImage] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);


  useEffect(() => {
    setOrgData(find(orgs, { id: org }) || {});

    switch (org) {
      case 'marketing-and-sales':
        setImage('marketing-sales-bg.jpeg');
        break;
      case 'technology':
        setImage('technology-bg.jpeg');
        break;
      case 'network':
        setImage('network-bg.jpeg');
        break;
      case 'insurance':
        setImage('insurance-bg.jpeg');
        break;
      case 'finance':
        setImage('finance-bg.jpeg');
        break;
      case 'hr':
        setImage('hr-bg.jpeg');
        break;
      default:
        setImage('main-bg.jpg');
        break;
    }
  }, [org]);

  const handleChange = (event, newValue) => {
    setSelectedIndex(newValue);
  };

  return (
    <div className={classes.root}>
      <Parallax
        bgImage={`/images/${image}`}
        bgImageAlt="bg image"
        strength={200}
        style={{ zIndex: -1 }}
      >
        <div className={classes.parallaxHeight}/>
      </Parallax>
      <div className={clsx(classes.standardRow)}>
        {orgData.content}
        <Grid
          container
          spacing={4}
          style={{ marginTop: 50 }}
        >
          <Hidden lgUp>
            <AppBar position="static">
              <Tabs
                onChange={handleChange}
                scrollButtons="on"
                value={selectedIndex}
                variant="scrollable"
              >
                {
                  orgData.listItems && orgData.listItems.map((item, itemIndex) =>
                    <Tab
                      label={item.title}
                      {...a11yProps(itemIndex)}
                    />
                  )
                }
              </Tabs>
            </AppBar>
          </Hidden>
          <Hidden mdDown>
            <Grid
              item
              xs={3}
            >
              <List>
                {
                  orgData.listItems && orgData.listItems.map((item, itemIndex) =>
                    <ListItem
                      button
                      className={clsx({
                        [classes.topListItem]: itemIndex === 0,
                        [classes.selectedItem]: itemIndex === selectedIndex
                      })}
                      divider
                      onClick={() => {
                        setSelectedIndex(itemIndex);
                      }}
                    >
                      <ListItemText primary={item.title}/>
                      <KeyboardArrowRight/>
                    </ListItem>
                  )
                }
              </List>
            </Grid>
          </Hidden>
          <Grid
            item
            lg={9}
            xs={12}
          >
            <Paper>
              <BcpTable
                headers={orgData.listItems && orgData.listItems[selectedIndex] && orgData.listItems[selectedIndex].headers}
                rows={orgData.listItems && orgData.listItems[selectedIndex] && orgData.listItems[selectedIndex].rows}
                title={orgData.listItems && orgData.listItems[selectedIndex] && orgData.listItems[selectedIndex].title}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
      <div className={classes.bottomDiv}/>
    </div>
  );
};

export default Home;
