import React, { cloneElement, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Parallax, Background } from 'react-parallax';
import { TopPageBox } from '../../components';
import {
  People as HRIcon,
  Assignment as ClaimsIcon,
  Language as NetworkingIcon,
  PermDataSetting as ITIcon,
  Pages,
  Notifications,
  LibraryBooks,
  AccountBalance as FinanceIcon,
  AttachMoney as SalesIcon,
  WarningRounded as HurricaneIcon
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import BCPDialog from '../../components/Dialog';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    height: '100%',
    width: '100%'
  },
  bottomDiv: {
    height: 300
  },
  image: {
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
  },
  icon: {
    fontSize: 50
  },
  topImage: {
    marginTop: -100
  },
  standardRow: {
    marginTop: -200,
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(15)}px ${theme.spacing(5)}px ${theme.spacing(15)}px`
    },
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`
    }
  },
  altRow: {
    minHeight: 100,
    marginTop: 100,
    backgroundColor: theme.palette.primary.light,
    padding: `${theme.spacing(5)}px ${theme.spacing(15)}px ${theme.spacing(5)}px ${theme.spacing(15)}px`
  },
  altRowText: {
    color: theme.palette.primary.contrastText
  },
  darkBlue: {
    backgroundColor: '#22313f'
  },
  standardDiv: {
    padding: theme.spacing(4),
    marginTop: 50
  },
  textCenter: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  topBg: {
    height: 600
  },
  parallaxHeight: {
    [theme.breakpoints.up('xs')]: {
      height: 200
    },
    [theme.breakpoints.up('md')]: {
      height: 400
    },
    [theme.breakpoints.up('lg')]: {
      height: 600
    }
  }
}));

const teams = [
  {
    name: 'Marketing & Sales',
    icon: (<SalesIcon/>),
    status: 'Green',
    link: '/marketing-and-sales/organization'
  },
  {
    name: 'Technology',
    icon: (<ITIcon/>),
    status: 'Yellow',
    link: '/technology/organization'
  },
  {
    name: 'Network',
    icon: (<NetworkingIcon/>),
    status: 'Green',
    link: '/network/organization'
  },
  {
    name: 'Insurance',
    icon: (<ClaimsIcon/>),
    status: 'Yellow',
    link: '/insurance/organization'
  },
  {
    name: 'Finance',
    icon: (<FinanceIcon/>),
    status: 'Red',
    link: '/finance/organization'
  },
  {
    name: 'HR',
    icon: (<HRIcon/>),
    status: 'Green',
    link: '/hr/organization'
  }
];

// const showDialog = true;


const Home = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);

  if (!localStorage.getItem('welcomeDisplayed')) {
    localStorage.setItem('welcomeDisplayed', '1');
    setOpenDialog(true);
  }


  return (
    <div className={classes.root}>
      <BCPDialog title={'Welcome to NSD Business Continuity Planning & Management Website'} content={
        (
          <React.Fragment>
            <img src={'/images/welcome.jpeg'} alt={'Welcome'} style={{ width: '100%', height: 'auto' }}/>
            <Typography gutterBottom variant={'h5'}>
              This website will keep employees up to date with all the information necessary to be informed during
              critical business events and situations.
            </Typography>
            <Typography gutterBottom variant={'h5'}>
              This website will be continually evolving and changing to always keep employees informed and connected.
              So
              be sure to bookmark it and return regularly to stay up to date.
            </Typography>
            <Typography gutterBottom variant={'h5'}>
              You will notice three major sections of the website; Resources, Communication, and General Info. Use
              these
              sections as your guide to find what you are looking for.
            </Typography>
            <Typography gutterBottom variant={'h5'}>
              Please remember to send any questions, concerns, and / or comments to
              <a href="mailto:bcp@nationsafedrivers.com?subject=NSD BCP Website"> bcp@nationsafedrivers.com.</a>
            </Typography>
            <Typography gutterBottom variant={'h3'}>
              Remember to use Chrome, FireFox, Safari or IE Edge browser
            </Typography>
            <Typography gutterBottom variant={'h5'}>
              Stay healthy and safe.
            </Typography>
          </React.Fragment>
        )
      } openDialog={openDialog}/>
      <Parallax
        bgImage={'/images/main-bg.jpg'}
        bgImageAlt="the cat"
        strength={200}
        style={{ zIndex: -1 }}
      >
        <div className={classes.parallaxHeight}/>
      </Parallax>
      <div className={clsx(classes.standardRow)}>
        <Grid container spacing={4}>
          <Grid item lg={3} sm={12}>
            <TopPageBox
              icon={<HurricaneIcon/>}
              title={'Hurricane Preparedness'}
              link={'hurricane-preparedness'}
              text={'Information about hurricane updates, policy, and preparedness'}
            />
          </Grid>
          <Grid item lg={3} sm={12}>
            <TopPageBox
              icon={<Pages/>}
              link={'resources'}
              title={'Resources'}
              text={'Toll free numbers, links, and key communication links'}
            />
          </Grid>
          <Grid item lg={3} sm={12}>
            <TopPageBox
              icon={<Notifications/>}
              title={'Communications'}
              link={'communications'}
              text={'Current and previous communications to keep you informed on all decision made.'}
            />
          </Grid>
          <Grid item lg={3} sm={12}>
            <TopPageBox
              icon={<LibraryBooks/>}
              title={'General Information'}
              link={'our-information'}
              text={'Information about our partners, customers and 3rd party vendors'}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.altRow}>
        <Grid container spacing={4}>
          {teams && teams.map((team, index) =>
            <Grid item xs={12} lg={2}>
              <Link to={team.link} key={`team${index}`} style={{ width: '100%' }}>
                <div style={{
                  height: 100,
                  backgroundColor: theme.palette.primary.main,
                  color: team.status === 'Red' ? theme.palette.error.main : team.status === 'Yellow' ? theme.palette.warning.main : theme.palette.success.main,
                  display: 'flex',
                  textAlign: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center'
                }}>
                  {
                    cloneElement(team.icon, {
                      ...team.icon.props,
                      className: classes.icon
                    })
                  }
                  <Typography variant={'h3'} style={{ color: theme.palette.primary.contrastText }}>
                    {team.name}
                  </Typography>
                </div>
              </Link>
            </Grid>
          )}
        </Grid>
      </div>
      <div className={clsx(classes.standardDiv)}>
        <Grid container spacing={4} style={{ marginBottom: 50 }}>
          <Grid item lg={4} sm={12}>
            <img src='/images/AdobeStock_191916117.jpeg' className={classes.image} style={{ width: '100%' }}/>
          </Grid>
          <Grid item lg={8} sm={12}>
            <div>
              <Typography variant={'h2'} style={{ paddingBottom: 10, paddingTop: 10 }}>
                NSD: Business Continuity Managed (BCM)
              </Typography>
            </div>
            <div>
              <Typography variant={'body1'}>
                Business Continuity is the capability of the organization to continue to delivery of products and
                services
                at acceptable predefined levels following a disruption. NSD defines disruptions such as cyber events,
                natural disasters, or man-made events that can interrupt NSD operations. The site incorporates NSD
                proactive measures to mitigate disruptive events and evaluate an NSD recovery capabilities.
                <br/>
                <br/>
                NSD has defined specific individual and roles for BCM governance. The role includes the following
                responsibilities.
                <br/>
                <br/>
              </Typography>
              <ul style={{ paddingLeft: 50 }}>
                <li>
                  Aligning BCM practices with the risk appetite.
                </li>
                <li>
                  Identifying the continuity level needed, consistent with the operation’s criticality.
                </li>
                <li>
                  Establishing business continuity policy and plans.
                </li>
                <li>
                  Allocating resources to BCM activities.
                </li>
                <li>
                  Providing competent management to implement the program.
                </li>
                <li>
                  Monitoring and assessing business continuity performance relative to these goals.
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.bottomDiv}/>
    </div>
  );
};

export default Home;
