import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  ExpansionPanel
} from '@material-ui/core';
import { Parallax } from 'react-parallax';
import { Animated } from 'react-animated-css';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    height: '100%',
    width: '100%'
  },
  bottomDiv: {
    height: 300
  },
  mainDiv: {
    padding: theme.spacing(4)
  },
  parallaxHeight: {
    [theme.breakpoints.up('xs')]: {
      height: 200
    },
    [theme.breakpoints.up('md')]: {
      height: 400
    },
    [theme.breakpoints.up('lg')]: {
      height: 600
    }
  },
  topGrid: {
    marginTop: 50
  }
}));

const HurricanePreparedness = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [companyStatus, setCompanyStatus] = useState('');

  useEffect(() => {
    let url = 'https://api.nationsafedrivers.com/nsd-api-internal-communication/v1/company-alert';
    fetch(`${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': '0a8bd89966dc473dbe70726fc510835f'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.recordset && data.recordset.length > 0) {
          setCompanyStatus(data.recordset[0].message);
        }

      })
      .catch(err => {
        console.log('graph error', err);
      });
  }, []);

  return (
    <div className={classes.root}>
      <Parallax
        bgImage={'/images/AdobeStock_170666516.jpeg'}
        bgImageAlt="the cat"
        strength={200}
        style={{ zIndex: -1 }}
      >
        <div className={classes.parallaxHeight}/>
      </Parallax>
      <div className={classes.mainDiv}>
        <Grid container spacing={4} className={classes.topGrid}>
          <Grid item xs={12}>
            <Card>
              <CardContent style={{ padding: theme.spacing(4), height: 250, overflowY: 'scroll' }}>
                <Typography variant={'h4'}>
                  Welcome to NSD Hurricane Readiness
                </Typography>
                <br/>
                <br/>
                <Typography variant={'h6'}>
                  {companyStatus}
                  <br/>
                  <br/>
                  The following site provides the latest details for <a
                  href={'https://earth.google.com/web/@21.82742374,-78.34208017,-503.43377479a,5872545.73951721d,35y,0h,0t,0r/data=Ci4SLBIgMmE1YThjNDc2ZDIxMTFlOTgxMjY2YjMzNGRiYmRhYTAiCGxheWVyc18w'}
                  target={'_blank'}>Tropical Storm Ian</a>.
                  <br/>
                  <br/>
                  For more information refer to the <a href={'/files/Hurricane Policy.pdf'} target={'_blank'}>NSD
                  Hurricane policy</a>.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

        </Grid>
      </div>
      <div className={classes.bottomDiv}/>
    </div>
  );
};

export default HurricanePreparedness;
