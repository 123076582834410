import React, { useEffect, useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Menu as MenuIcon, Input as InputIcon, Message, HelpOutline, Notifications } from '@material-ui/icons';
import { AppBar, Toolbar, IconButton, Typography, Hidden, Fab, Badge, Popover } from '@material-ui/core';
import { Animated } from 'react-animated-css';
import moment from 'moment';

var tinycolor = require('tinycolor2');

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    backgroundColor: '#FFFFFF',
    borderBottom: '1px solid #eeeeee'
    // height:200
  },
  title: {
    flexGrow: 1
  },
  statusAlerts: {
    position: 'fixed',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    padding: `${theme.spacing(4)}px 0px ${theme.spacing(4)}px ${theme.spacing(4)}px`,
    // top: 80,
    bottom: 0,
    right: 0,
    width: '100%',
    minHeight: 70,
    backgroundColor: tinycolor(theme.palette.primary.main).setAlpha(.5).toRgbString()
  },
  emailBCP: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    padding: theme.spacing(2)
  },
  notification: {
    color: theme.palette.error.main
  },
  typography: {
    padding: theme.spacing(2)
  },
  noNotification: {
    color: theme.palette.success.main
  }
}));

const Topbar = props => {
  const { className, history, onSidebarOpen, ...rest } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [messageVisible, setMessageVisible] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [statusMessageIndex, setStatusMessageIndex] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [statusMessages] = useState([
    'Hurricane policy available in our Resources page under Essential Documents.',
    (<React.Fragment>
      Our business is considered essential. Find the office travel document under Resources -> Essential Documents.
    </React.Fragment>),
    'Main sites are open with cross site visitation restrictions in place.',
    'Overall 75% complete of BCP execution and virtual office availability.',
    'All events at NSD have been cancelled till future notice',
    'As of Monday, March 23rd , we will be closing the lunchroom',
    'We have established and internal only 888-673-4847 (888-NSD-ITGR) for virtual office for IT support.'
  ]);

  useEffect(() => {
    let user = localStorage.getItem('msData') ? JSON.parse(localStorage.getItem('msData')) : null;
    if (user && user.authResponseWithAccessToken.account.idToken.roles && user.authResponseWithAccessToken.account.idToken.roles.indexOf('Admin') >= 0) {
      setIsAdmin(true);
    }
    let url = 'https://api.nationsafedrivers.com/nsd-api-internal-communication/v1/company-alert';
    fetch(`${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': '0a8bd89966dc473dbe70726fc510835f'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.recordset && data.recordset.length > 0 && moment(data.recordset[0].expire) >= moment()) {
          // if (data.recordset && data.recordset.length > 0) {
          setNotifications(notifications => {
            notifications.push(data.recordset[0].message);
            return notifications;
          });
        }

      })
      .catch(err => {
        console.log('graph error', err);
      });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (!messageVisible) {
        if (statusMessageIndex < statusMessages.length - 1) {
          setStatusMessageIndex(statusMessageIndex + 1);
        } else {
          setStatusMessageIndex(0);
        }
      }
      setMessageVisible(!messageVisible);
    }, messageVisible ? 7000 : 1000);
  }, [messageVisible]);

  const handleNotificationsClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationsClick = (event) => {
    if (notifications.length > 0)
      setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      // color="primary"
      position="fixed"
    >

      <div className={classes.statusAlerts}>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Message style={{ marginRight: 10 }}/>
            <Typography style={{ color: theme.palette.primary.contrastText }}> Message system</Typography>
          </div>
          <div style={{
            borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
            width: '100%',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
          }}></div>
          <Animated animationIn="fadeInLeft" animationOut="fadeOutRight" animationInDuration={1000}
                    animationOutDuration={1000} isVisible={messageVisible}>
            <Typography style={{ color: theme.palette.primary.contrastText }}
                        variant={'h3'}>{statusMessages[statusMessageIndex]}</Typography>
          </Animated>
        </div>
      </div>
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/nation-safe-drivers.png"
          />
        </RouterLink>
        <div className={classes.title}>
          <RouterLink to="/">
            <Typography variant="h2">
              NSD
            </Typography>
            <Typography variant="h4">
              Business Continuity Plan and Management
            </Typography>
          </RouterLink>
        </div>
        <Hidden mdDown>
          {isAdmin && <div style={{ padding: theme.spacing(2) }}>
            <RouterLink to="/company-emergency-notification">
              Emergency Notification
            </RouterLink>
          </div>}
          <div style={{ padding: theme.spacing(2) }}>
            <RouterLink to="/hurricane-preparedness">
              Hurricane Preparedness
            </RouterLink>
          </div>
          <div style={{ padding: theme.spacing(2) }}>
            <RouterLink to="/resources">
              Resources
            </RouterLink>
          </div>
          <div style={{ padding: theme.spacing(2) }}>
            <RouterLink to="/communications">
              Communications
            </RouterLink>
          </div>
          <div style={{ padding: theme.spacing(2) }}>
            <RouterLink to="/our-information">
              General Info
            </RouterLink>
          </div>
          <IconButton aria-describedby={id} onClick={handleNotificationsClick}>
            <Badge badgeContent={notifications.length} color="primary">
              <Notifications
                className={notifications.length > 0 && !localStorage.getItem('readNotification') ? classes.notification : classes.noNotification}/>
            </Badge>
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleNotificationsClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <Typography className={classes.typography}>
              {notifications && notifications.length > 0 && notifications.join((<br/>))}
            </Typography>
          </Popover>
          <IconButton
            className={classes.signOutButton}
            onClick={() => {
              localStorage.removeItem('id_token');
              localStorage.removeItem('msData');
              history.push('/');
            }}
          >
            <InputIcon/>
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton onClick={onSidebarOpen}>
            <MenuIcon/>
          </IconButton>
        </Hidden>
      </Toolbar>
      <div className={classes.emailBCP}>
        <a href={'mailto:bcp@nationsafedrivers.com?subject=NSD BCP Website'}>
          <Fab color="primary" aria-label="email">
            <HelpOutline/>
          </Fab>
        </a>
      </div>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default withRouter(Topbar);
