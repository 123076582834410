import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  ExpansionPanel
} from '@material-ui/core';
import { Parallax } from 'react-parallax';
import { Animated } from 'react-animated-css';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    height: '100%',
    width: '100%'
  },
  bottomDiv: {
    height: 300
  },
  mainDiv: {
    padding: theme.spacing(4)
  },
  parallaxHeight: {
    [theme.breakpoints.up('xs')]: {
      height: 200
    },
    [theme.breakpoints.up('md')]: {
      height: 400
    },
    [theme.breakpoints.up('lg')]: {
      height: 600
    }
  },
  topGrid: {
    marginTop: 50
  }
}));

const externalComm = {
  overview: (
    <React.Fragment>
      <Typography variant={'h6'}>
        We recognize that with the constantly changing coronavirus (COVID-19) situation around the world, this is an
        unprecedented time for everyone. In response to the current situation, we’ve implemented our business continuity
        plan which examines all areas of business operations at NSD. We are taking actions to ensure continued service
        to our customers. You can rest assured that our plan is to continue to serve you with the same High-
        Touch/High-Tech approach that you have become accustomed to.
        <br/>
        <br/>
        Below are details of our approach across several key dimensions. We hope you find reassurance that we are well
        prepared, focused, and remaining agile as things evolve.
      </Typography>
    </React.Fragment>
  ),
  people: (
    <React.Fragment>
      <Typography variant={'h6'}>
        The health and wellness of our employees is our top priority. We are closely monitoring updates from the CDC and
        WHO, as well as local health department guidance for locations where we have office locations. We are providing
        consistent updates to our employees as well as providing educational resources on prevention. We have increased
        janitorial services across our large office locations and ensured sanitary supplies are stocked.
        <br/><br/>
        We have led a campaign to update emergency contact information and instructed sick employees to stay home. We
        have provided education on our policies regarding leaves and paid sick time to ensure that we allow the
        flexibility for employees to care for themselves and their families.
        <br/><br/>
        Like many companies, we have restricted non-essential business travel. We have encouraged employees to use
        virtual methods for larger group meetings.
        <br/><br/>
        We will continue to monitor the landscape to see if/when we should expand guidance.
      </Typography>
    </React.Fragment>
  ),
  opsAndProcess: (
    <React.Fragment>
      <Typography variant={'h6'}>
        We have developed detailed business resiliency plans across our core operations including sales, marketing,
        roadside assistance, claims management, customer service, and billing. We are well positioned given our scale
        and current volume to ensure we see no drop in service. We have a remote work plan in place and are training
        managers on how to engage, monitor and coach employees should that become necessary. We have instituted video
        conferencing to ensure our high touch/high technology philosophy continues across all sites.
        <br/><br/>
        Warehouse and shipping require employees to be present at NSD. Communal spaces in the fulfilment centers
        (cafeterias, restrooms, copier areas) as well as doorknobs are being cleaned and disinfected multiple times a
        day. Hand sanitizer and hospital grade wipes are available to all employees We have contingent labor plans in
        the unlikely event of a large communal spread in this group.
      </Typography>
    </React.Fragment>
  ),
  technology: (
    <React.Fragment>
      <Typography variant={'h6'}>
        We are well prepared to support increased remote work through our secure VPN and remote desktop applications. We
        are enabling virtual office for our employee to continue to support our customers across the nation. We
        conducted an audit of equipment and access and have increased our capacity so that more employees than typical
        can access the network virtually.
        <br/><br/>
        We have asked all employees with a laptop to take their equipment home each night. We are also running a
        campaign to educate and test connectivity for employees who do not routinely work remotely.
      </Typography>
    </React.Fragment>
  ),
  vendorNetwork: (
    <React.Fragment>
      <Typography variant={'h6'}>
        We have created an e-mail address, bcp@nationsafedrivers.com, and have communicated to our partners & suppliers
        to send any issues related to the virus to this mailbox. We are likewise asking them for their business
        resiliency plans. We will swiftly communicate any disruptions to our sales teams and to our customers
      </Typography>
    </React.Fragment>
  ),
  customers: (
    <React.Fragment>
      <Typography variant={'h6'}>
        We are proactively working with customers to evaluate all options while making sure we are focused on making
        sure we communicate as conditions evolve. We view our relationship with our clients as a true partnership; and
        these are not transactional, they are truly meaningful and appreciated.
      </Typography>
    </React.Fragment>
  ),
  closing: (
    <React.Fragment>
      <Typography variant={'h6'}>
        NSD has taken these precautionary measures to limit the potential spread of the virus, to support our employees
        and to ensure we maintain our ability to serve you, our customer, for the long term. Our senior leadership team
        is monitoring the situation in real time and responding rapidly as conditions evolve.
        <br/>
        <br/>
        Be safe and stay healthy,
        <br/>

        <br/>
        Mike Wiener NSD CEO
      </Typography>
    </React.Fragment>
  )
};

const messages = [
  {
    date: 'Message on 3/14/2020',
    subject: 'NSD Plan: Coronavirus (COVID-19) Update',
    message: (
      <React.Fragment>
        <Typography variant={'h6'}>
          Greeting All,
          <br/>
          <br/>
          We want you to always know that the health and well-being of our associates is our priority. The NSD executive
          team is actively monitoring the coronavirus (COVID-19) situation and making decisions accordingly. At this
          time, we are restricting access between office sites. This means you should remain at your site for work.
          Please use email and phone to conduct business with associates who are located in other offices. If you are
          feeling ill, please reach out to your supervisor before coming to the office.
          <br/>
          <br/>
          We will continue monitor the situation and will be providing an update on regular basis.
          <br/>
          <br/>
          Be well.
        </Typography>
      </React.Fragment>
    )
  },
  {
    date: 'Message on 3/17/2020',
    subject: 'NSD Plan: Coronavirus (COVID-19) Update',
    message: (
      <React.Fragment>
        <Typography variant={'h6'}>
          The NSD executive team is actively monitoring the coronavirus (COVID-19) situation and making decisions
          accordingly. Our associate's health and well-being are our priority. In response to the current situation, NSD
          is implementing our business continuity plan — which examines all areas of business operations at every
          location. At this time:
          <br/>
          <br/>
          <ul>
            <li>All events at NSD have been cancelled till future notice.</li>
            <li>We are asking each of our employees to stay on their working floors and use the same entrance and exit
              doors. If you work on the 1st floor at HQ, please stay on that floor and exit through the door you
              entered. If you work on the 2nd floor, please stay on that floor and exit through the door you entered. If
              you have a meeting with someone on the other floor, please conduct your meeting via telephone if possible.
            </li>
            <li></li>
            <li>We are actively working on the virtual office and will assess which departments and associates will have
              access so that we can maintain our business operations. We have already established 888-673-4847
              (888-NSD-ITGR) for virtual office for IT support.
            </li>
          </ul>
          <br/>
          <br/>
          These precautionary measures have been taken to limit the potential spread of the virus to support our
          associates. I will be sending out an update on Thursday.
          <br/>
          <br/>
          Be safe and stay healthy.
        </Typography>
      </React.Fragment>
    )
  },
  {
    date: 'Update 3/18/2020 – Corporate Office',
    subject: 'NSD Plan: Coronavirus (COVID-19) Update',
    message: (
      <React.Fragment>
        <Typography variant={'h6'}>
          In continuing our efforts to take precautionary measures, we are asking our associates that work on the 2nd
          floor to enter and exit only through the front lobby door. Our 1st floor associates should only enter through
          the east parking lot lunchroom doors. As mentioned in yesterday’s communication, please remain on the floor
          you work on and conduct your communication via telephone or email.
          <br/>
          <br/>
          As of Monday, March 23rd , we will be closing the lunchroom this will include the use of all appliances
          (except microwaves that have not been relocated). You may have lunch at your desk, but please remember to be
          courteous of others around you. You will also need to remember to punch in and out. During this time, we will
          also need to discontinue our coffee service in order to eliminate sharing the handling of the coffee pot.
          <br/>
          <br/>
          Everyone’s support is very much appreciated as these efforts are truly to ensure associate health and
          well-being. Please know that the executive team is monitoring daily and will continue to make adjustments to
          these policies.
          <br/>
          Thank you for your patience and be safe and healthy.
        </Typography>
      </React.Fragment>
    )
  },
  {
    date: 'Update 3/19/2020 – Corporate Office',
    subject: 'NSD Plan: Coronavirus (COVID-19) Update',
    message: (
      <React.Fragment>
        <Typography variant={'h6'}>
          Greetings All,
          <br/>
          <br/>
          As promised the Executive Team’s objective is to maintain open communication with our associates during these
          unprecedented times. As you may be aware the President just signed the Families First Coronavirus Response Act
          into law. Our team is working diligently to review the new law so that we can assist in any way possible.
          <br/>
          <br/>
          We are making excellent progress with our Virtual Office (work from home) testing of our critical systems. If
          we are unable to come into the office, NSD would be able to continue operating our business.
          <br/>
          <br/>
          Curfews and business closures have also been a concern for many. We believe that we qualify as an “Essential
          Business”; therefore, we may stay open and our associates may leave their home to come to work. This means
          that if any county has a curfew, our associates are still able to come in and earn their pay! This is evolving
          really quickly, and we will keep you informed as soon as we know.
          <br/>
          <br/>
          We are also launching our Business Continuity Planning & Management website
          <a href={'https://bcp.nationsafedrivers.com/sign-in/'}>https://bcp.nationsafedrivers.com/sign-in/</a> to keep
          associates informed. For Office 365 (O365) users, you can log in with your Office 365 account. If you have not
          been migrated to O365, you can use the following account: nsdbcm@nationsafedrivers.com (Password was supplied
          via email. Best experience with Chrome, Safari or IE Edge browsers)
          <br/>
          <br/>
          We share everyone’s hopes for health, safety and welcome the return to all aspects of life. Until then, we
          stand strong together as NSD Owners!
        </Typography>
      </React.Fragment>
    )
  },
  {
    date: 'Update 3/20/2020 – Corporate Office',
    subject: 'NSD Plan: Coronavirus (COVID-19) Update',
    message: (
      <React.Fragment>
        <Typography variant={'h6'}>
          In an effort to ensure that we successfully deliver unmatched and continuous services for our customers, we
          are rolling out Virtual Office (work from home) effective Monday, March 23. I want to thank all of my
          teammates for proactively validating the virtual office option this week.
          <br/>
          <br/>
          We are going live, in limited capacity, with virtual office with Marketing/sales, Gap, IT, claim processing,
          network operations, and HR. We will also continue to have limited staff from each of these departments coming
          to the office. Unless otherwise notified, any division not mentioned here needs to be in the office until
          further notification.
          <br/>
          <br/>
          On March 23, NSD call center & contact center will operate remotely with everyone using their virtual office.
          The call centers new operation hours are from 8 am to 7 pm (EST) are 7 days a week. The team will be working
          on all Avis, Zipcar, and Mercedes dispatches, along with our Out-of-Network assistance. Please make sure to
          continue to provide excellent service to our customers and tow truck providers, and let’s really focus on
          controlling our service costs.
          <br/>
          <br/>
          The contact center will operate from 8:30 am to 5 pm (EST) Monday through Friday with continuous focus on all
          claims.
          <br/>
          <br/>
          Your key resource for Business Continuity Planning & Management is https://bcp.nationsafedrivers.com/sign-in/.
          For Office 365 (O365) users, you can log in with your Office 365 account. If you have not been migrated to
          O365, you can login with the following account: nsdbcm@nationsafedrivers.com with password “N$ddR@cks!” (Best
          experience with Chrome, Safari or IE Edge browsers)
          <br/>
          <br/>
          Everyone’s support is very much appreciated! We share everyone’s hopes for health, safety and welcome the
          return to all aspects of life. Until then, we stand strong together as NSD Owners!
        </Typography>
      </React.Fragment>
    )
  }

];

const Communicaitons = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [readMore, setReadMore] = useState(true);
  const [showCommContent, setShowCommContent] = useState(false);

  return (
    <div className={classes.root}>
      <Parallax
        bgImage={'/images/communication-bg.jpeg'}
        bgImageAlt="the cat"
        strength={200}
        style={{ zIndex: -1 }}
      >
        <div className={classes.parallaxHeight}/>
      </Parallax>
      <div className={classes.mainDiv}>
        <Grid container spacing={4} className={classes.topGrid}>
          <Grid item xs={12}>

            <Card>
              <CardHeader title={'March 18, 2020'}/>
              <CardContent style={{ padding: theme.spacing(4), height: 250, overflowY: 'scroll' }}>
                <Typography variant={'h6'}>External Communication</Typography>
                <br/>
                <br/>
                {externalComm.overview}
                <br/>
                <br/>

                <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={1000}
                          animationOutDuration={1000} isVisible={!readMore}>
                  {showCommContent && <div>
                    {externalComm.people}
                    <br/>
                    <br/>
                    {externalComm.opsAndProcess}
                    <br/>
                    <br/>
                    {externalComm.technology}
                    <br/>
                    <br/>
                    {externalComm.vendorNetwork}
                    <br/>
                    <br/>
                    {externalComm.customers}
                    <br/>
                    <br/>
                    {externalComm.closing}
                  </div>}
                </Animated>
              </CardContent>
              <CardActions>
                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <Button onClick={() => {
                    if (readMore) {
                      setShowCommContent(true);
                    } else {
                      setTimeout(() => {
                        setShowCommContent(false);
                      }, 1250);
                    }
                    setReadMore(!readMore);
                  }}>{readMore ? 'Read More' : 'Read Less'}</Button>
                </div>
              </CardActions>
            </Card>
          </Grid>
          {messages && messages.map((msg, index) =>
            <Grid xs={12} lg={4} item>
              <Card>
                <CardHeader title={msg.date}/>
                <CardContent style={{ padding: theme.spacing(4), height: 250, overflowY: 'scroll' }}>
                  <Typography variant={'h6'}>{msg.subject}</Typography>
                  <br/>
                  <br/>
                  <Typography variant={'h6'}>{msg.message}</Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </div>
      <div className={classes.bottomDiv}/>
    </div>
  );
};

export default Communicaitons;
