import { colors } from '@material-ui/core';

var tinycolor = require('tinycolor2');

const white = '#FFFFFF';
const black = '#000000';
const primaryColor = '#22313f';
const secondaryColor = '#d5dae2';
const tertiaryColor = '#13646e';

const warningColor = '#ff9800';
const errorColor = '#f44336';
const successColor = '#4caf50';
const infoColor = '#2196f3';

//tinycolor(primaryColor).darken(8).toHexString()
export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: tinycolor(primaryColor).darken(8).toHexString(),
    main: primaryColor,
    light: tinycolor(primaryColor).lighten(28).toHexString()
  },
  secondary: {
    contrastText: white,
    dark: tinycolor(secondaryColor).darken(8).toHexString(),
    main: secondaryColor,
    light: tinycolor(secondaryColor).lighten(28).toHexString()
  },
  tertiary: {
    contrastText: white,
    dark: tinycolor(tertiaryColor).darken(8).toHexString(),
    main: tertiaryColor,
    light: tinycolor(tertiaryColor).lighten(28).toHexString()
  },
  success: {
    contrastText: white,
    dark: tinycolor(successColor).darken(8).toHexString(),
    main: successColor,
    light: tinycolor(successColor).lighten(28).toHexString()
  },
  info: {
    contrastText: white,
    dark: tinycolor(infoColor).darken(8).toHexString(),
    main: infoColor,
    light: tinycolor(infoColor).lighten(28).toHexString()
  },
  warning: {
    contrastText: white,
    dark: tinycolor(warningColor).darken(8).toHexString(),
    main: warningColor,
    light: tinycolor(warningColor).lighten(28).toHexString()
  },
  error: {
    contrastText: white,
    dark: tinycolor(errorColor).darken(8).toHexString(),
    main: errorColor,
    light: tinycolor(errorColor).lighten(28).toHexString()
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  background: {
    default: '#FFFFFF',
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200]
};
