import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  NotFound as NotFoundView,
  Home as HomeView,
  SignIn as SignInView,
  Resources as ResourcesView,
  Organization as OrganizationView,
  OurInformation as OurInformationView,
  Communicaitons as CommunicaitonsView,
  CompanyEmergencyNotification as CompanyEmergencyNotificationView,
  HurricanePreparedness as HurricanePreparednessView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/home"
      />
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MainLayout}
        path="/home"
        // publicRoute
      />
      <RouteWithLayout
        component={ResourcesView}
        exact
        layout={MainLayout}
        path="/resources"
        // publicRoute
      />
      <RouteWithLayout
        component={HurricanePreparednessView}
        exact
        layout={MainLayout}
        path="/hurricane-preparedness"
        // publicRoute
      />
      <RouteWithLayout
        component={CompanyEmergencyNotificationView}
        exact
        layout={MainLayout}
        path="/company-emergency-notification"
        // publicRoute
      />
      <RouteWithLayout
        component={CommunicaitonsView}
        exact
        layout={MainLayout}
        path="/communications"
        // publicRoute
      />
      <RouteWithLayout
        component={OurInformationView}
        exact
        layout={MainLayout}
        path="/our-information"
        // publicRoute
      />
      <RouteWithLayout
        component={OrganizationView}
        exact
        layout={MainLayout}
        path="/:org/organization"
        // publicRoute
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
        publicRoute
      />

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MainLayout}
        path="/not-found"
        publicRoute
      />
      <Redirect to="/not-found"/>
    </Switch>
  );
};

export default Routes;
