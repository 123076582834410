import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  ExpansionPanel, TextField, Checkbox, Link, FormHelperText
} from '@material-ui/core';
import { Parallax } from 'react-parallax';
import { Animated } from 'react-animated-css';
import validate from 'validate.js';
import { Link as RouterLink } from 'react-router-dom';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateMomentUtils from '@date-io/moment';
import MuiTypography from '../../theme/overrides/MuiTypography';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    height: '100%',
    width: '100%'
  },
  bottomDiv: {
    height: 300
  },
  mainDiv: {
    padding: theme.spacing(4)
  },
  parallaxHeight: {
    [theme.breakpoints.up('xs')]: {
      height: 200
    },
    [theme.breakpoints.up('md')]: {
      height: 400
    },
    [theme.breakpoints.up('lg')]: {
      height: 600
    }
  },
  topGrid: {
    marginTop: 50
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  }
}));

const schema = {
  message: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 600
    }
  },
  expire: {
    presence: { allowEmpty: false, message: 'is required' }
  }
};

const CompanyEmergencyNotification = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [currentMessage, setCurrentMessage] = useState('');
  const [currentMessageExpire, setCurrentMessageExpire] = useState('');
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      expire: moment().add(24, 'hours')
    },
    touched: {},
    errors: {}
  });

  useEffect(() => {
    let url = 'https://api.nationsafedrivers.com/nsd-api-internal-communication/v1/company-alert';
    // let url = 'http://localhost:7071/api/company-alert';
    fetch(`${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': '0a8bd89966dc473dbe70726fc510835f'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.recordset && data.recordset.length > 0) {
          setCurrentMessage(data.recordset[0].message);
          setCurrentMessageExpire(data.recordset[0].expire);
        }

      })
      .catch(err => {
        console.log('graph error', err);
      });
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    console.log('formState.values: errors', errors);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event && event.persist && event.persist();

    console.log(event);
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleDateChange = name => event => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [name]: event
      },
      touched: {
        ...formState.touched,
        [name]: true
      }
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleSignUp = event => {
    event.preventDefault();
    let url = 'https://api.nationsafedrivers.com/nsd-api-internal-communication/v1/company-alert';
    // let url = 'http://localhost:7071/api/company-alert';
    fetch(`${url}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': '0a8bd89966dc473dbe70726fc510835f'
      },
      body: JSON.stringify({
        message: formState.values.message,
        expire: formState.values.expire
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log('All good', data);
      })
      .catch(err => {
        console.log('graph error', err);
      });
  };

  return (
    <div className={classes.root}>
      <Parallax
        bgImage={'/images/AdobeStock_170666516.jpeg'}
        bgImageAlt="the cat"
        strength={200}
        style={{ zIndex: -1 }}
      >
        <div className={classes.parallaxHeight}/>
      </Parallax>
      <div className={classes.mainDiv}>
        <Grid container spacing={4} className={classes.topGrid}>
          <Grid item xs={3}>
            <Card>
              <CardHeader
                title={'Current Message'}
                subheader={currentMessage ? `Effective until ${moment(currentMessageExpire).format('ddd LLL')}` : ''}
              />
              <CardContent style={{ padding: theme.spacing(4), height: 250, overflowY: 'scroll' }}>
                <Typography variant={'h6'}>
                  {currentMessage || 'Not currently set'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={9}>
            <Card>
              <CardHeader title={'Set New Message'}/>
              <CardContent className={classes.contentBody}>
                <Typography variant={'h6'}>
                  <form
                    className={classes.form}
                    onSubmit={handleSignUp}
                  >
                    <Typography
                      className={classes.title}
                      variant="h2"
                    >
                      Create new company emergency notification
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                    >
                      Enter the message employees should receive and for how long they should receive the message.
                    </Typography>
                    <TextField
                      multiline
                      rowsMax={10}
                      className={classes.textField}
                      error={hasError('message')}
                      fullWidth
                      helperText={
                        hasError('message') ? formState.errors.message[0] : null
                      }
                      label="Message"
                      name="message"
                      onChange={handleChange}
                      value={formState.values.message || ''}
                      variant="outlined"
                    />
                    <MuiPickersUtilsProvider utils={DateMomentUtils}>
                      <DateTimePicker
                        utils={DateMomentUtils}
                        className={classes.textField}
                        error={hasError('expire')}
                        fullWidth
                        helperText={
                          hasError('expire') ? formState.errors.expire[0] : null
                        }
                        label="Expire Date & Time"
                        name="expire"
                        onChange={handleDateChange('expire')}
                        value={formState.values.expire || ''}
                        inputVariant="outlined"
                      />
                    </MuiPickersUtilsProvider>
                    <Button
                      className={classes.signUpButton}
                      color="primary"
                      disabled={!formState.isValid}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Update Message
                    </Button>
                  </form>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <div className={classes.bottomDiv}/>
    </div>
  );
};

export default CompanyEmergencyNotification;
