import React, { Fragment, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Grid, Hidden,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText, Paper,
  AppBar,
  Tabs,
  Tab
} from '@material-ui/core';
import { BcpTable } from '../../components';
import { Link } from 'react-router-dom';
import { ThreeSixty, Videocam, PermPhoneMsg, KeyboardArrowRight, CloudDownload } from '@material-ui/icons';
import { Parallax } from 'react-parallax';
import clsx from 'clsx';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

var tinycolor = require('tinycolor2');

const useStyles = makeStyles(theme => ({
  root: {},
  bottomDiv: {
    height: 300
  },
  selectedItem: {
    backgroundColor: tinycolor(theme.palette.primary.light).setAlpha(.5).toRgbString()
  },
  topListItem: {
    borderTop: '1px solid #eeeeee'
  },
  standardRow: {
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(15)}px ${theme.spacing(5)}px ${theme.spacing(15)}px`
    },
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`
    }
  },
  altRow: {
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.up('lg')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(15)}px ${theme.spacing(5)}px ${theme.spacing(15)}px`
    },
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`
    }
  },
  altRowText: {
    color: theme.palette.primary.contrastText
  },
  parallaxHeight: {
    [theme.breakpoints.up('xs')]: {
      height: 250
    },
    [theme.breakpoints.up('md')]: {
      height: 400
    },
    [theme.breakpoints.up('lg')]: {
      height: 500
    }
  },
  singleItemTopDiv: {
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    borderTopLeftRadius: 55,
    borderBottomLeftRadius: 55
  },
  singleItemIcon: {
    borderRadius: 50,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.main,
    padding: 10,
    marginLeft: -10,
    marginRight: 10
  },
  singleItemText: {
    color: theme.palette.primary.contrastText
  },
  singleItemContent: { display: 'flex', flexDirection: 'column', padding: 10 }
}));

const keyPartnersInfo = {
  title: 'Key Partners Information',
  headers: [
    {
      name: 'Company'
    }, {
      name: 'Name'
    }, {
      name: 'Position'
    }, {
      name: 'Vendor Type'
    }, {
      name: 'Number'
    }, {
      name: 'eMail'
    }],
  rows: [
    [{ value: 'ASK' }, { value: 'Rick Burley' }, { value: 'Owner' }, { value: '3rd Party Call Center' }, {
      value: '334-224-7413',
      type: 'phone'
    }, { value: 'rburley@asktelemarketing.com' }],
    [{ value: 'ASK' }, { value: 'Michael Lawrence' }, { value: 'Manager' }, { value: '3rd Party Call Center' }, {
      value: '334-233-7467',
      type: 'phone'
    }, { value: 'mlawrence@asktelemarketing.com' }],
    [{ value: 'ASK' }, { value: 'Forest Burley' }, { value: 'Manager' }, { value: '3rd Party Call Center' }, {
      value: '334-202-4515',
      type: 'phone'
    }, { value: 'Fburley@asktelemarketing.com' }],
    [{ value: 'OnBrand' }, { value: 'Chris Alexander' }, { value: 'SVP' }, { value: '3rd Party Call Center' }, {
      value: '813-766-0945',
      type: 'phone'
    }, { value: 'calexander@onbrand24.com' }],
    [{ value: 'OnBrand' }, { value: 'Joe Vondracek' }, { value: 'VP' }, { value: '3rd Party Call Center' }, {
      value: '712-251-9022',
      type: 'phone'
    }, { value: 'vondracek@massmarkets.com' }],
    [{ value: 'OnBrand' }, { value: 'Josh LeBlanc' }, { value: 'Manager' }, { value: '3rd Party Call Center' }, {
      value: '978-471-0795',
      type: 'phone'
    }, { value: 'jleblanc@onbrand24.com' }],
    [{ value: 'InContact' }, { value: 'Brandon Bottorff' }, { value: 'Account Manager' }, { value: 'Cloud Telephony' }, {
      value: '385-321-0665',
      type: 'phone'
    }, { value: 'brandon.bottorff@niceincontact.com' }],
    [{ value: 'ABG' }, { value: 'Kevin Schendel' }, { value: 'ERS Manager' }, { value: 'Avis Roadside' }, {
      value: '407-487-9593',
      type: 'phone'
    }, { value: 'kevin.schendel@avisbudget.com' }],
    [{ value: 'ABG' }, { value: 'Dean Ferden' }, { value: 'ERS Director' }, { value: 'Avis Roadside' }, {
      value: '404-427-3162',
      type: 'phone'
    }, { value: 'dean.ferden@avisbudget.com' }],
    [{ value: 'ABG' }, { value: 'Nate Whybrew' }, { value: 'Impounds Manager' }, { value: 'Avis Impounds' }, {
      value: '757-687-2334',
      type: 'phone'
    }, { value: 'nathan.whybrew@avisbudget.com' }],
    [{ value: 'ABG - Zipcar' }, { value: 'Ryan Gramaglia' }, { value: 'Zipcar Manager' }, { value: 'Zipcar ERS' }, {
      value: '857-319-6374',
      type: 'phone'
    }, { value: 'rgramaglia@zipcar.com' }],
    [{ value: 'MBUSA' }, { value: 'Chris Carter' }, { value: 'Supervisor' }, { value: 'ERS MBUSA' }, {
      value: '551-579-1134',
      type: 'phone'
    }, { value: 'christopher.carter@mbusa.com' }],
    [{ value: 'Amtrust' }, { value: 'Michael Butler' }, { value: 'Sr. VP Claims' }, { value: '' }, {
      value: (<React.Fragment><a href={'tel:857-400-3228'}>857-400-3228 Office</a><br/><a href={'tel:617-455-9410'}>617-455-9410
        Cell</a></React.Fragment>)
    }, { value: 'Michael.butler@amtrustgroup.com' }],

    [{ value: 'Amtrust' }, { value: 'Michael Butler' }, { value: 'Sr. VP Claims' }, { value: '' }, {
      value: '617-455-9410',
      type: 'phone'
    }, { value: 'Michael.butler@amtrustgroup.com' }],

    [{ value: 'AmTrust Financial Services, Inc.' }, { value: 'Luis Zamora' }, { value: 'Claims Analyst II' }, { value: '' }, {
      value: '858-924-8083',
      type: 'phone'
    }, { value: 'srclaimsgap@amtrustgroup.com' }],
    [{ value: 'Carco' }, { value: 'Bobby Lefko' }, { value: 'Claims Manager' }, { value: 'Comprehensive Auto Resources' }, {
      value: '877-902-8790 x 128',
      type: 'phone'
    }, { value: 'bobby@carcogap.com' }],
    [{ value: 'Carco' }, { value: 'Charlie Coronia' }, { value: '' }, { value: '' }, { value: '' }, { value: 'charlie@carcogap.com' }],
    [{ value: 'Classic' }, { value: 'Brad Hughes' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }],
    [{ value: 'Nissan Motor Acceptance Corp (NMAC)' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }]
  ]
};

const bcmGovernanceTeam = {
  title: 'NSD BCM Governance Team',
  headers: [{
    name: 'Name'
  }, {
    name: 'BCM Role'
  }, {
    name: 'Phone'
  }],
  rows: [
    [{ value: 'Rajiv Amar' }, { value: 'Coordinator & Security' }, { value: '(858) 775-5194', type: 'phone' }],
    [{ value: 'Jac Alvarez' }, { value: 'Marketing & Sales' }, { value: '561-351-9466', type: 'phone' }],
    [{ value: 'Jeff Wiener' }, { value: 'Network/Operations' }, { value: '714-791-6190', type: 'phone' }],
    [{ value: 'Howard Goldfarb' }, { value: 'Insurance/Claims' }, { value: '954-304-2255', type: 'phone' }],
    [{ value: 'Trevor Moore' }, { value: 'IT/Applicators/Facilities' }, { value: '(707) 292-1444', type: 'phone' }],
    [{ value: 'Lisa Clark' }, { value: 'Finance' }, { value: '954-214-1172', type: 'phone' }],
    [{ value: 'Wanda Lopez' }, { value: 'HR' }, { value: '954-980-4168', type: 'phone' }]
  ]
};

const tollFreeNumbers = {
  title: 'Toll Free Numbers',
  headers: [{
    name: 'Toll Free'
  }, {
    name: 'Role'
  }, {
    name: 'Description'
  }, {
    name: 'Ring-To #'
  }, {
    name: 'Routing'
  }],
  rows: [
    [{
      value: '800-338-2680',
      type: 'phone'
    }, { value: 'Main Line' }, { value: 'NSD Main Menu options' }, { value: '561-226-3600' }, { value: 'Ext / Claims / Customer Svc / Cancellations / Marketing / Network / Vendor Support / NIU' }],
    [{
      value: '800-362-8054',
      type: 'phone'
    }, { value: 'RV Tech Call Center' }, { value: 'Canadian CC' }, { value: '925-298-6772' }, { value: 'External Call Center - specific to one Dealer / Company ' }],
    [{
      value: '800-424-2404',
      type: 'phone'
    }, { value: 'Main Line' }, { value: 'NSD Main Menu options' }, { value: '561-226-3600' }, { value: 'Ext / Claims / Customer Svc / Cancellations / Marketing / Network / Vendor Support / NIU' }],
    [{
      value: '800-711-9171',
      type: 'phone'
    }, { value: 'Main Line' }, { value: 'NSD Main Menu options' }, { value: '561-226-3600' }, { value: 'Ext / Claims / Customer Svc / Cancellations / Marketing / Network / Vendor Support / NIU' }],
    [{
      value: '833-205-9111',
      type: 'phone'
    }, { value: 'General Council' }, { value: 'Frank Espoisito’s number' }, { value: '212-537-3896' }, { value: 'Direct Line to Frank\'s assistant' }],
    [{
      value: '844-776-8869',
      type: 'phone'
    }, { value: 'Emergency Roadside Assistance only' }, { value: '9/30/19 Jon B. requested this reroute IT#80014' }, { value: '925-298-6773' }, { value: 'Set up by Jon B. IT 80014' }],
    [{
      value: '844-835-6972',
      type: 'phone'
    }, { value: 'Main Line' }, { value: 'Alternative local number, same as 3600' }, { value: '561-226-3606' }, { value: 'Ext / Claims / Customer Svc / Cancellations / Marketing / Network / Vendor Support / NIU' }],
    [{
      value: '855-246-6007',
      type: 'phone'
    }, { value: 'Emergency Roadside Assistance only' }, { value: '3rd party call center  /  reached Montgomery this time' }, { value: '925-203-3834' }, { value: 'External Contractor Call Center / Set up by Jon B.' }],
    [{
      value: '855-246-6008',
      type: 'phone'
    }, { value: 'Internal Claims Center' }, { value: 'Gerry\'s Team / Federal Hwy' }, { value: '561-221-6747' }, { value: 'Federal Hwy Call Center' }],
    [{
      value: '855-246-6009',
      type: 'phone'
    }, { value: 'Internal Claims Center' }, { value: 'Gerry\'s Team / Federal Hwy' }, { value: '561-221-6747' }, { value: 'Federal Hwy Call Center' }],
    [{
      value: '855-276-9304',
      type: 'phone'
    }, { value: 'Emergency Roadside Assistance only' }, { value: '3rd party call center / Jon B. (Montgomery)' }, { value: '925-203-3834' }, { value: 'External Contractor Call Center / Set up by Jon B.' }],
    [{
      value: '855-511-5420',
      type: 'phone'
    }, { value: 'Dead number, rings 5 times and dies' }, { value: 'Routed w/ Dave on IT 78156' }, { value: '904-914-3531' }, { value: 'Bad Number' }],
    [{
      value: '855-766-7461',
      type: 'phone'
    }, { value: 'Emergency Roadside Assistance only' }, { value: '3rd party call center / (Jon B. requested IT 80002)' }, { value: '925-203-3834' }, { value: 'External Contractor Call Center / Set up by Jon B.' }],
    [{
      value: '866-277-6014',
      type: 'phone'
    }, { value: 'Verizon Conferencing / Not in use' }, { value: 'Bad 800 Number' }, { value: '561-332-4009' }, { value: 'Bad Number' }],
    [{
      value: '866-263-6261',
      type: 'phone'
    }, { value: 'Toll Free Fax Line' }, { value: 'Reroutes to local fax number' }, { value: '561-314-4170' }, { value: 'Fax Line - Dealer Tire Set up on IT 70439 / IT 70935' }],
    [{
      value: '866-268-9254',
      type: 'phone'
    }, { value: 'Emergency Roadside Assistance only' }, { value: '3rd party call center / (Jon B. requested IT 80002)' }, { value: '925-203-3834' }, { value: 'External Contractor Call Center / Set up by Jon B.' }],
    [{
      value: '866-269-0103',
      type: 'phone'
    }, { value: 'Emergency Roadside Assistance only' }, { value: '3rd party call center / (Jon B. requested IT 80002)' }, { value: '925-203-3834' }, { value: 'External Contractor Call Center / Set up by Jon B.' }],
    [{
      value: '866-269-0107',
      type: 'phone'
    }, { value: 'Emergency Roadside Assistance only' }, { value: '3rd party call center / (Jon B. requested IT 80002)' }, { value: '925-203-3834' }, { value: 'External Contractor Call Center / Set up by Jon B.' }],
    [{
      value: '866-269-3639',
      type: 'phone'
    }, { value: 'Emergency Roadside Assistance only' }, { value: '3rd party call center / (Jon B. requested IT 80002)' }, { value: '925-203-3834' }, { value: 'External Contractor Call Center / Set up by Jon B.' }],
    [{
      value: '866-282-3627',
      type: 'phone'
    }, { value: 'Emergency Roadside Assistance only' }, { value: '3rd party call center / (Jon B. requested IT 80002)' }, { value: '925-203-3834' }, { value: 'External Contractor Call Center / Set up by Jon B.' }],
    [{
      value: '866-330-1238',
      type: 'phone'
    }, { value: 'Internal Claims Center' }, { value: 'Gerry\'s Team / Federal Hwy' }, { value: '561-221-6747' }, { value: 'Claims, no options - direct to NSD Claims Call Queue' }],
    [{
      value: '866-588-0743',
      type: 'phone'
    }, { value: 'Internal Claims Center' }, { value: 'Gerry\'s Team / Federal Hwy' }, { value: '561-221-6747' }, { value: 'Claims, no options - direct to NSD Claims Call Queue' }],
    [{
      value: '866-693-4393',
      type: 'phone'
    }, { value: 'NSD National Adjustment Bureau NAB' }, { value: 'Internal NIU / Howard G./Craig Calver\'s Team' }, { value: '561-226-3604' }, { value: 'NIU x506' }],
    [{
      value: '877-334-0315',
      type: 'phone'
    }, { value: 'Internal Claims Center' }, { value: 'Gerry\'s Team / Federal Hwy' }, { value: '561-221-6747' }, { value: 'Claims, no options - direct to NSD Call Queue' }],
    [{
      value: '888-272-5517',
      type: 'phone'
    }, { value: 'NSD Claims Dept / Internal Allison\'s Team' }, { value: 'GAP Department ' }, { value: '561-314-4083' }, { value: 'Gap Queue x534' }],
    [{
      value: '888-362 7805',
      type: 'phone'
    }, { value: 'NSD Vendor Support Queue' }, { value: 'Vendor Support Queue direct ' }, { value: '561-226-1125' }, { value: 'Vendor Support x578' }],
    [{
      value: '888-362-7850',
      type: 'phone'
    }, { value: 'Internal Fax Line ' }, { value: 'Townet Fax Line' }, { value: '561-584-6634' }, { value: 'Fax Line' }],
    [{
      value: '888-843-5873',
      type: 'phone'
    }, { value: 'NSD Motor Club, Internal NSD menu' }, { value: 'Allows dial by ext, or dial by name, not usual main menu' }, { value: '561-226-1125' }, { value: 'NSD Menu' }],
    [{
      value: '888-899-3606',
      type: 'phone'
    }, { value: 'NIU Call Queue' }, { value: 'Internal NIU / Howard G./Craig Calver\'s Team' }, { value: '561-226-1129' }, { value: 'Direct to NIU Call Queue' }]
  ]
};

const installDocs = {
  title: 'User Installation Docs',
  headers: [{
    name: 'doc'
  }],
  rows: [
    [{
      value: 'CXone and inContact Max Guide with Trouble Shoot.pdf',
      link: '/inContact/CXone and inContact Max Guide with Trouble Shoot.pdf',
      type: 'ext_link'
    }],
    [{
      value: 'Detailed CXone and InContact Max Guide.pdf',
      link: '/inContact/Detailed CXone and InContact Max Guide.pdf',
      type: 'ext_link'
    }],
    [{
      value: 'Download and Install CXone Softphone.docx',
      link: '/inContact/Download and Install CXone Softphone.docx',
      type: 'ext_link'
    }]
  ]
};

const licenseInfo = {
  title: 'License information',
  headers: [],
  rows: []
};

const softwareDownloads = {
  title: 'Software Downloads',
  headers: [
    {
      name: 'Document'
    }, {
      name: 'Description'
    }
  ],
  rows: [
    [{ value: '', link: '', type: 'ext_link' }]
  ]
};

const applicaitons = {
  title: 'Application Access',
  headers: [
    {
      name: 'Application'
    },
    {
      name: 'Link'
    },
    {
      name: 'Pre-Requisits'
    }
  ],
  rows: [
    [{ value: 'NSD Applications (Affinity, NSD Dispatch, Camiel)' }, {
      value: 'https://nsdmc.org/cheyenne/logon.asp',
      type: 'ext_link'
    }, { value: 'Requires NSD Login' }],
    [{ value: 'NSD Mail' }, {
      value: 'https://nsdmc.org/cheyenne/logon.asp',
      type: 'ext_link'
    }, { value: 'Requires NSD Login' }],
    [{ value: 'Teleconferences/Video Conferences' }, {
      value: 'https://nationsafedrivers.zoom.us',
      type: 'ext_link'
    }, { value: 'Requires O365 Login' }],
    [{ value: 'O365 Mail' }, {
      value: 'https://portal.office.com',
      type: 'ext_link'
    }, { value: 'Requires O365 Login' }],
    [{ value: 'O  & S Drive' }, { value: '' }, { value: 'Requires VPN & NSD Login' }],
    [{ value: 'One Drive' }, {
      value: 'https://portal.office.com',
      type: 'ext_link'
    }, { value: 'Requires O365 Login' }],
    [{ value: 'InContact' }, {
      value: 'https://login.incontact.com/inContact/Login.aspx',
      type: 'ext_link'
    }, { value: 'Requires INContact Login' }],
    [{ value: 'Kronos' }, {
      value: 'https://secure4.saashr.com/ta/6141769.login?rnd=1567025749830&FS=',
      type: 'ext_link'
    }, { value: 'NSD Login' }],
    [{ value: 'QuickBooks' }, { value: '' }, { value: 'Requires VPN & NSD Login' }]
  ]
};

const essentialDocuments = {
  title: 'Essential Documents',
  headers: [
    {
      name: 'Document'
    },
    {
      name: 'Link'
    }
  ],
  rows: [
    [
      { value: 'Hurricane Policy' },
      {
        value: (
          <React.Fragment>
            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
              <CloudDownload/>
              <div style={{ marginLeft: 5, display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                Document Download
              </div>
            </div>
          </React.Fragment>),
        link: '/files/Hurricane Policy.pdf',
        type: 'ext_link'
      }
    ],
    [
      { value: 'TRAA Letter_Legal Opinion_Essential Critical Infrastructure Worker' },
      {
        value: (
          <React.Fragment>
            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
              <CloudDownload/>
              <div style={{ marginLeft: 5, display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                Document Download
              </div>
            </div>
          </React.Fragment>),
        link: '/files/TRAA Letter_Legal Opinion_Essential Critical Infrastructure Worker.pdf',
        type: 'ext_link'
      }
    ],
    [
      { value: 'Return to Work Guide' },
      {
        value: (
          <React.Fragment>
            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
              <CloudDownload/>
              <div style={{ marginLeft: 5, display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                Document Download
              </div>
            </div>
          </React.Fragment>),
        link: '/files/Return to Work Guide.pdf',
        type: 'ext_link'
      }
    ]
  ]
};

const listItems = [bcmGovernanceTeam, essentialDocuments, keyPartnersInfo,
  installDocs, softwareDownloads, applicaitons];

const Resources = () => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setSelectedIndex(newValue);
  };

  return (
    <div className={classes.root}>
      <Parallax
        bgImage={'/images/resources-bg.jpeg'}
        bgImageAlt="the cat"
        strength={400}
        style={{ zIndex: -1 }}
      >
        <div className={classes.parallaxHeight}></div>
      </Parallax>
      <div className={classes.standardRow}>
        <Typography variant={'h1'} className={classes.altRowText}>Key Communication</Typography>
        <br/>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            lg={4}
            xs={12}
          >
            <div
              className={classes.singleItemTopDiv}>
              <div className={classes.singleItemIcon}>
                <ThreeSixty style={{ fontSize: 50 }}/>
              </div>
              <div className={classes.singleItemContent}>
                <div>
                  <Typography variant={'h3'} className={classes.singleItemText}>Overall Coordinator</Typography>
                </div>
                <div>
                  <Typography variant={'h4'} className={classes.singleItemText}>
                    Rajiv Amar <a href={'tel:(858) 775-5194'}>(858) 775-5194</a>
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            lg={4}
            xs={12}
          >
            <div
              className={classes.singleItemTopDiv}>
              <div className={classes.singleItemIcon}>
                <Videocam style={{ fontSize: 50 }}/>
              </div>
              <div className={classes.singleItemContent}>
                <div>
                  <Typography variant={'h3'} className={classes.singleItemText}>Video Conference</Typography>
                </div>
                <div>
                  <Typography variant={'h4'} className={classes.singleItemText}>
                    <a href={'https://nationsafedrivers.zoom.us'}>Zoom (Requires Registration)</a>
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            lg={4}
            xs={12}
          >
            <div
              className={classes.singleItemTopDiv}>
              <div className={classes.singleItemIcon}>
                <PermPhoneMsg style={{ fontSize: 50 }}/>
              </div>
              <div className={classes.singleItemContent}>
                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant={'h3'} className={classes.singleItemText}>IT Helpdesk</Typography>
                </div>
                <div>
                  <Typography variant={'h4'} className={classes.singleItemText}>
                    <a href={'tel:888-673-4847'}>888-673-4847 </a><a
                    href={'to:bcp@nationsafedrives.com'}>bcp@nationsafedrives.com</a>
                  </Typography>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center' }}>
              <Typography variant={'h5'}>
                * For Internal Use and Emergencies Only
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginTop: 50 }}>
          <Hidden lgUp>
            <AppBar position="static">
              <Tabs
                scrollButtons="on"
                variant="scrollable"
                value={selectedIndex}
                onChange={handleChange}>
                {
                  listItems && listItems.map((item, itemIndex) =>
                    <Tab label={item.title}{...a11yProps(itemIndex)} />
                  )
                }
              </Tabs>
            </AppBar>
          </Hidden>
          <Hidden mdDown>
            <Grid item xs={3}>
              <List>
                {
                  listItems && listItems.map((item, itemIndex) =>
                    <ListItem
                      className={clsx({
                        [classes.topListItem]: itemIndex === 0,
                        [classes.selectedItem]: itemIndex === selectedIndex
                      })}
                      divider
                      button
                      onClick={() => {
                        setSelectedIndex(itemIndex);
                      }}
                    >
                      <ListItemText primary={item.title}/>
                      <KeyboardArrowRight/>
                    </ListItem>
                  )
                }
              </List>
            </Grid>
          </Hidden>
          <Grid item xs={12} lg={9}>
            <Paper>
              <BcpTable
                title={listItems[selectedIndex].title}
                headers={listItems[selectedIndex].headers}
                rows={listItems[selectedIndex].rows}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
      <div className={classes.bottomDiv}></div>
    </div>
  );
};

export default Resources;
