import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  IconButton, Hidden, TableContainer
} from '@material-ui/core';
import { FilterList as FilterListIcon } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';

var tinycolor = require('tinycolor2');

const useStyles = makeStyles(theme => ({
  root: {},
  toolbarRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  toolbarHighlight: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main
  },
  toolbarTitle: {
    flex: '1 1 100%'
  }
}));

const TopPageBox = props => {
  const { title, filter, headers, rows, ...rest } = props;
  const [hiddenColumns] = useState(props.hiddenColumns || []);
  const theme = useTheme();
  const classes = useStyles();

  const createRowLink = (cell, cellIndex, rowIndex) => {
    if (cell.type === 'phone') {
      return (
        <TableRow key={`cell${rowIndex}${cellIndex}`}>
          <a href={`tel:${cell.link || cell.value}`}>{cell.value}</a>
        </TableRow>);
    }
    if (cell.type === 'ext_link') {
      return (
        <TableRow key={`cell${rowIndex}${cellIndex}`}>
          <a href={`${cell.link || cell.value}`} target={'_blank'}>{cell.value}</a>
        </TableRow>);
    }
  };
  const createCellLink = (cell, cellIndex, rowIndex) => {
    if (cell.type === 'phone') {
      return (
        <TableCell key={`cell${rowIndex}${cellIndex}`}>
          <a href={`tel:${cell.link || cell.value}`}>{cell.value}</a>
        </TableCell>);
    }
    if (cell.type === 'ext_link') {
      return (
        <TableCell key={`cell${rowIndex}${cellIndex}`}>
          <a href={`${cell.link || cell.value}`} target={'_blank'}>{cell.value}</a>
        </TableCell>);
    }
  };
  return (
    <div>
      <Toolbar
        className={clsx(classes.toolbarRoot, {
          [classes.toolbarHighlight]: true
        })}
        color={'secondary'}
      >
        <Typography
          className={classes.toolbarTitle}
          id="tableTitle"
          variant="h2"
        >
          {title}
        </Typography>
        {filter && <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon/>
          </IconButton>
        </Tooltip>}
      </Toolbar>
      <Hidden lgUp>
        <TableContainer>
          <Table size={'small'}>
            <TableBody>
              {
                rows && rows.map((row, rowIndex) =>
                  <TableRow
                    style={{ backgroundColor: rowIndex % 2 === 0 ? tinycolor(theme.palette.tertiary.light).setAlpha(.05).toRgbString() : '' }}
                  >
                    <TableCell
                      style={{
                        fontWeight: 'bold', flex: 1, textAlign: 'right', display: 'flex',
                        flexDirection: 'column'
                      }}
                      align={'right'} size={'small'}
                      variant={'head'}>
                      {
                        headers && headers.map((header, headerIndex) => {
                            return hiddenColumns.indexOf(headerIndex) < 0 ?
                              <TableRow key={`header${headerIndex}`}>
                                {header.name}
                              </TableRow>
                              : null;
                          }
                        )
                      }
                    </TableCell>
                    <TableCell style={{ flex: 1 }}>
                      {row && row.map((cell, cellIndex) => {
                        if (cell.type) {
                          return createRowLink(cell, cellIndex, rowIndex);
                        }
                        return (<TableRow key={`cell${rowIndex}${cellIndex}`}>{cell.value}</TableRow>);
                      })}
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Hidden>
      <Hidden mdDown>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {
                  headers && headers.map((header, headerIndex) => {
                      return hiddenColumns.indexOf(headerIndex) < 0 ?
                        <TableCell variant={'head'} key={`header${headerIndex}`}>{header.name}</TableCell>
                        :
                        null;
                    }
                  )
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                rows && rows.map((row, rowIndex) =>
                  <TableRow
                    style={{ backgroundColor: rowIndex % 2 === 0 ? tinycolor(theme.palette.tertiary.light).setAlpha(.05).toRgbString() : '' }}
                    key={`row${rowIndex}`}
                  >
                    {row && row.map((cell, cellIndex) => {
                      if (hiddenColumns.indexOf(cellIndex) >= 0) {
                        return null;
                      } else {
                        if (cell.type) {
                          return createCellLink(cell, cellIndex, rowIndex);
                        }
                        return (<TableCell key={`cell${rowIndex}${cellIndex}`}>{cell.value}</TableCell>);
                      }
                    })}
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Hidden>
    </div>
  );
};

TopPageBox.propTypes = {
  filter: PropTypes.func,
  headers: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.object),
  hiddenColumns: PropTypes.arrayOf(PropTypes.number)
};

export default TopPageBox;
