import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Typography } from '@material-ui/core';
import { AcUnit as AcUnitIocn } from '@material-ui/icons';

var tinycolor = require('tinycolor2');

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    flex: 1,
    zIndex: 1000
  },
  topDiv: {
    flex: 1,
    height: '100%'
  },
  topDivMid: {
    flex: 1,
    height: '73%'
  },
  divSpacer: {
    height: 100
  },
  divCenter: {
    padding: theme.spacing(4),
    width: '100%',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: `${tinycolor(theme.palette.primary.main).setAlpha(.85).toRgbString()}`
  },
  iconWrapper: {
    marginTop: -theme.spacing(14),
    paddingBottom: theme.spacing(2)
  },
  textCenter: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    border: `6px solid ${theme.palette.primary.main}`
  },
  icon: {
    fontSize: 100,
    color: theme.palette.primary.main
  },
  title: {
    padding: theme.spacing(2),
    color: theme.palette.primary.contrastText
  },
  body: {
    padding: theme.spacing(2),
    color: theme.palette.primary.contrastText
  }
}));

const TopPageBox = props => {
  const { className, title, text, icon, link, ...rest } = props;

  const classes = useStyles();

  return (
    <RouterLink to={link} className={classes.topDiv}>
      <div className={classes.topDiv}>
        <div className={clsx(classes.root, className, classes.topDiv)}>
          <div className={clsx(classes.divSpacer, classes.topDiv)}></div>
          <div className={clsx(classes.divCenter, classes.topDivMid)}>
            <div className={classes.iconWrapper}>
              <Avatar alt="Remy Sharp" className={classes.large}>
                <div>
                  {
                    icon ?
                      cloneElement(icon, {
                        ...icon.props,
                        className: classes.icon
                      })
                      :
                      <AcUnitIocn className={classes.icon}/>
                  }

                </div>
              </Avatar>
            </div>
            <div>
              <Typography variant={'h3'} className={classes.title}>
                {title}
              </Typography>
            </div>
            <div className={classes.textCenter}>
              <Typography variant={'body1'} className={classes.body}>
                {text}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </RouterLink>
  );
};

TopPageBox.propTypes = {
  className: PropTypes.object,
  icon: PropTypes.object,
  title: PropTypes.string,
  text: PropTypes.string
};

export default TopPageBox;
